import { Button } from "@material-ui/core";
import { Upload, Image, Row, Spin } from "antd";
import firebase from "firebase";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface ImageFile {
  imageFile: string;
  imagePreviewUrl: string;
}
interface Props {
  children: any;
  handleChange: (imageFile: ImageFile) => void;
  value: string;
  previewValue: string;
}

const storage = firebase.storage();
const loaderStyle: any = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const ImageUpload: React.FC<Props> = ({
  children,
  handleChange,
  value,
  previewValue,
}: Props) => {
  const [visible, setVisible] = useState(false);
  const [uploading, setUploading] = useState(false);
  const { t } = useTranslation();
  const onRemove = async () => {
    let storageRef = storage.ref();
    await storageRef.child(value).delete();
    let imageFile = {
      imageFile: "",
      imagePreviewUrl: "",
    };
    handleChange(imageFile);
  };

  const handleUpload = async ({ file, onSuccess, onError }: any) => {
    setUploading(true);
    const storageRef = storage.ref();
    const imageFileName = file.name.split(".").slice(0, -1).join(".");
    let saveUrl = `images/job/${
      imageFileName ? imageFileName + "-" + Date.now() : Date.now()
    }.jpg`;
    const storageImageRef = storageRef.child(saveUrl);
    try {
      await storageImageRef.put(file);
      const downloadUrl = await storageImageRef.getDownloadURL();
      onSuccess?.("ok");
      let imageFile = {
        imageFile: saveUrl,
        imagePreviewUrl: downloadUrl,
      };
      handleChange(imageFile);
      setUploading(false);
    } catch (err: any) {
      setUploading(false);
      onError?.(err);
    }
  };

  const getFileNameFromFileUrl = (fileName: string) => {
    let baseName = fileName.split("images/job/")[1];
    if (!baseName || baseName.length < 1) return "";
    return `${baseName?.split("-")[0]}.${baseName?.split(".")[1]}`;
  };

  return (
    <>
      <Row justify="space-between">
        {uploading && (
          <div style={loaderStyle}>
            <Spin size="large"></Spin>
          </div>
        )}
        <Upload
          accept="image/*"
          customRequest={handleUpload}
          fileList={
            value
              ? [
                  {
                    uid: "1",
                    name: getFileNameFromFileUrl(value),
                    url: previewValue,
                  },
                ]
              : []
          }
          disabled={uploading}
          onRemove={onRemove}
        >
          {children}
        </Upload>
        {value?.length > 1 && (
          <Button variant="outlined" onClick={() => setVisible(true)}>
            {t("Preview")}
          </Button>
        )}
      </Row>
      <Image
        width={200}
        style={{ display: "none" }}
        src={previewValue}
        preview={{
          visible,
          src: previewValue,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />
    </>
  );
};

export { ImageUpload };
