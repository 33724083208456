import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import firebase from "firebase";
import { buildResultsDocs, catchExceptionCallback } from "utils";
import Form from "./Form";
import DashboardLayout from "pages/DashboardLayout/Dashboard";

interface ICreateUpdateProps
  extends RouteComponentProps<{ id: string; companyId: string }> {}

const CreateUpdate: React.FC<ICreateUpdateProps> = (props) => {
  const {
    match: {
      params: { id, companyId },
    },
  } = props;
  const [store, setStore] = useState<any>(null);
  const [users, setUsers] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snap = await firebase
          .firestore()
          .collection("Stores")
          .doc(id)
          .get();
        const userSnapshot = await firebase
          .firestore()
          .collection("Admins")
          .where("companyId", "==", companyId)
          .get();
        setStore(snap.data());
        setUsers(buildResultsDocs(userSnapshot));
      } catch (error) {
        catchExceptionCallback(error);
      }
    };
    fetchData();
  }, [id, companyId]);

  return (
    <DashboardLayout>
      <Form store={store} companyId={companyId} id={id} users={users} />
    </DashboardLayout>
  );
};

export default CreateUpdate;
