import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import firebase from "../../firebase";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MaterialButton from "@material-ui/core/Button";
import { Button, Tooltip, Space, Popconfirm, Table, Tag } from "antd";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { TextField } from "@material-ui/core";
import "antd/dist/antd.css";
import { DashBoardContext } from "pages/DashboardLayout/DashboardContext";
import DashboardLayout from "pages/DashboardLayout/Dashboard";
import { t } from "i18next";
import { Job } from "components/interfaces";
import {
  BUSINESS_TYPE,
  EMPLOYMENT_TYPE,
} from "components/Molecules/Job/helpers";
import { generateDraftUrl, getWebUrl } from "utils";
import { FileCopyOutlined } from "@material-ui/icons";

let lastFetchedJobData: string[] = [];

interface Props extends WithStyles<typeof styles> {}

const db = firebase.firestore();

const gotoDraftView = (id: string) => {
  window.open(generateDraftUrl(id, 1), "_blank", "noopener,noreferrer");
};

const generatePreview = (data: Job) => {
  if (data?.hasPublished) window.open(`${getWebUrl()}/job/${data?.id}`);
  else gotoDraftView(data?.id ?? "");
};

const columns = (onDelete: any) => [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (jobId: string) => <>{jobId}</>,
  },
  {
    title: "タイトル",
    dataIndex: "",
    key: "タイトル",
    render: (data: any) => <>{data?.title ?? data?.draft?.title}</>,
  },
  {
    title: t("Business Type"),
    dataIndex: "businessType",
    key: "翻訳状況",
    render: (value: string, data: any) => (
      <>
        {t(
          BUSINESS_TYPE.find(
            (item) => item.value === (value ?? data?.draft?.businessType)
          )?.label ?? ""
        )}
      </>
    ),
  },
  {
    title: t("Employment Type"),
    dataIndex: "employmentType",
    key: "isPublic",
    render: (employmentType: string, data: any) => (
      <>
        {t(
          EMPLOYMENT_TYPE.find(
            (item) =>
              item.value === (employmentType ?? data?.draft?.employmentType)
          )?.label ?? ""
        )}
      </>
    ),
  },
  {
    title: t("Page status"),
    key: "isPublic",
    render: (_: unknown, data: Job) => (
      <>
        {data?.hasPublished && data?.isPublic === "yes" ? (
          <Tag color="green">{t("Published")}</Tag>
        ) : (
          <Tag color="red">{t("Draft")}</Tag>
        )}
      </>
    ),
  },
  {
    title: t("Action"),
    dataIndex: "id",
    key: "edit",
    render: (id: string, data: Job) => (
      <Space>
        <Button icon={<EyeOutlined />} onClick={() => generatePreview(data)} />
        <Link to={`jobs/${id}`}>
          <Button icon={<EditOutlined />}></Button>
        </Link>
        <Link to={`jobs/${db.collection("Jobs").doc().id}#${id}`}>
          <Button icon={<FileCopyOutlined />}></Button>
        </Link>
        <Popconfirm
          title={t("Are you sure to delete this job?")}
          onConfirm={() => onDelete(id)}
          cancelText={t("Cancel")}
          okText={t("Confirm")}
        >
          <Tooltip placement="right" title={t("Delete")}>
            <Button danger icon={<DeleteOutlined />} />
          </Tooltip>
        </Popconfirm>
      </Space>
    ),
  },
];

const styles = createStyles({
  searchWrapper: {
    border: "2px solid #DEDBD5",
    borderRadius: "10px",
    margin: "25px 40px 25px 40px",
    padding: "30px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  add: {
    padding: "25px 80px 20px 20px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#F8F5EF",
    borderRadius: "5px",
  },
  text: {
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: 500,
  },
  searchLabel: {
    display: "flex",
    marginTop: "5px",
    marginRight: "15px",
    alignItems: "center",
    color: "#000000",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 500,
  },
  addButton: {
    padding: "10px 20px 10px 20px",
    backgroundColor: "#39404F",
    borderRadius: "5px",
    border: "1px solid #39404F",
    cursor: "pointer",
    fontSize: "13px",
    color: "#FFFFFF",
    lineHeight: "20px",
    fontWeight: 500,
  },
});

const industryList: any = BUSINESS_TYPE;

function Jobs(props: Props) {
  const history = useHistory();
  const { setCurrentTitle } = useContext(DashBoardContext);
  const { classes } = props;
  const [jobList, setJobList] = useState([] as any[]);
  const [canFetchJobs, setCanFetchJobs] = useState(true);
  const [loading, setLoading] = useState(false);
  const [fetchedTime, setFetchedTime] = useState(0);
  const [fetchedAllJobs, setFetchedAllJobs] = useState(false);
  const [, setCompanyList] = useState<any>({});
  const [searchOptions, setSearchOptions] = useState({
    industry: "0",
    keyword: undefined,
  } as any);

  useEffect(() => {
    setCurrentTitle("求人");
    getCompanyList();
    lastFetchedJobData = [];
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [setCurrentTitle]);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >
      document.documentElement.offsetHeight - 200
    ) {
      setCanFetchJobs(true);
    }
  };

  useEffect(() => {
    if (canFetchJobs) {
      setCanFetchJobs(false);
    }
  }, [canFetchJobs, jobList]);

  const getCompanyList = async () => {
    const collection = await db.collection("Companies").get();

    const list: any = {};
    collection.docs.forEach((doc) => {
      list[doc.id] = doc.data();
    });
    setCompanyList(list);
  };

  const getJobList = useCallback(async () => {
    setLoading(true);
    setFetchedTime((prev) => prev + 1);
    let query = db.collection("Jobs").orderBy("updatedAt", "desc").limit(40);

    if (lastFetchedJobData.length) {
      query = query.startAfter(...lastFetchedJobData);
    }

    if (searchOptions.industry !== "0" || searchOptions.keyword)
      query = db.collection("Jobs").limit(40);
    if (searchOptions.industry !== "0") {
      query = query.where("businessType", "==", searchOptions.industry);
    }
    if (searchOptions.keyword)
      query = query.where("title", "==", searchOptions.keyword);

    const spanShot = await query.get();
    const list: any[] = [];
    setFetchedAllJobs(spanShot.docs.length < 40);
    if (spanShot.docs.length) {
      spanShot.docs.forEach((doc) => {
        const data = doc.data();
        data.id = doc.id;
        list.push(data);
      });
      if (lastFetchedJobData.length) {
        const uniqueData: any = [];
        let uniqueKey: string[] = [];
        jobList.forEach((item) => {
          if (!uniqueKey.includes(item.id)) {
            uniqueData.push(item);
            uniqueKey.push(item.id);
          }
        });
        list.forEach((item) => {
          if (!uniqueKey.includes(item.id)) {
            uniqueData.push(item);
            uniqueKey.push(item.id);
          }
        });
        setJobList(uniqueData);
      } else {
        setJobList([...list]);
      }
      const lastJob = list[list.length - 1];
      lastFetchedJobData = [lastJob.updatedAt];
    } else if (!lastFetchedJobData.length) {
      setJobList([]);
    }
    setLoading(false);
  }, [jobList, searchOptions.industry, searchOptions.keyword]);

  const changeSearchOptions = (event: any) => {
    lastFetchedJobData = [];

    const target = event.target as HTMLSelectElement;
    setSearchOptions({ ...searchOptions, [target.name]: target.value });
  };

  const deleteJob = (key: string) => {
    db.collection("Jobs")
      .doc(key)
      .delete()
      .then(() => setJobList((prev) => prev.filter((item) => item.id !== key)));
  };

  useEffect(() => {
    if (!canFetchJobs || fetchedAllJobs) {
      return;
    }
    getJobList();
  }, [canFetchJobs, fetchedAllJobs, getJobList, searchOptions]);

  const handleSearch = () => {
    setFetchedAllJobs(false);
    setCanFetchJobs(true);
  };

  const getJobId = () => db.collection("Jobs").doc().id;
  return (
    <DashboardLayout>
      <div>
        <div className={classes.searchWrapper}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSearch();
            }}
          >
            <div>
              <Space>
                <div className={classes.searchLabel}>
                  <p>求人カテゴリ</p>
                </div>
                <FormControl
                  variant="outlined"
                  style={{ minWidth: "240px" }}
                  component={"div"}
                >
                  <Select
                    name={"industry"}
                    value={searchOptions.industry}
                    onChange={changeSearchOptions}
                    input={<OutlinedInput labelWidth={0} />}
                  >
                    <MenuItem value={"0"}>{t("Select Business Type")}</MenuItem>
                    {industryList.map((industry: any, index: any) => (
                      <MenuItem
                        value={industry.value}
                        key={`industry_${index}`}
                      >
                        {t(industry.label)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className={classes.searchLabel}>
                  <p style={{ margin: "0 1rem" }}>キーワード</p>
                  <FormControl
                    variant="outlined"
                    style={{ minWidth: "240px" }}
                    component={"div"}
                  >
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      name="keyword"
                      placeholder="求人タイトルから検索"
                      onChange={changeSearchOptions}
                    />
                  </FormControl>
                </div>
                <MaterialButton
                  variant="contained"
                  color="primary"
                  disabled={loading ? true : false}
                  type="submit"
                >
                  探す
                </MaterialButton>
              </Space>
            </div>
            <div></div>
          </form>
          <div>
            <button
              onClick={() => history.push(`jobs/${getJobId()}`)}
              className={classes.addButton}
            >
              追加
            </button>
          </div>
        </div>
        <Paper>
          <Table
            dataSource={jobList}
            columns={columns(deleteJob)}
            rowKey="id"
            loading={fetchedTime <= 1 && loading}
            style={{ padding: "0.5em" }}
          />
        </Paper>
      </div>
    </DashboardLayout>
  );
}

export default withStyles(styles)(Jobs);
