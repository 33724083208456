import React, { useEffect, useState } from "react";
import firebase from "firebase";
import { Button, Space, Table, Popconfirm, Tooltip } from "antd";
import MaterialButton from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import InputChip from "components/InputChip";
import { EditOutlined, DeleteOutlined } from "@material-ui/icons";
import {
  createStyles,
  Paper,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import "antd/dist/antd.css";
import { useTranslation } from "react-i18next";
import {
  buildDataFromDocs,
  buildResultsDocs,
  catchExceptionCallback,
} from "utils";

const db = firebase.firestore();

const styles = createStyles({
  userHeader: {
    marginTop: "35px",
    display: "flex",
    justifyContent: "space-between",
  },
  addButton: {
    marginBottom: "20px",
  },
});

interface Props extends WithStyles<typeof styles> {
  companyId: string;
}

const getStoreId = () => {
  return db.collection(`Stores`).doc().id;
};

const _StoreList: React.FC<Props> = ({ classes, companyId }: Props) => {
  const history = useHistory();
  const [stores, setStores] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<any>({});
  const { t } = useTranslation();

  const columns = (onDelete: any, history: any, companyId: string) => [
    {
      title: t("ID"),
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("Store Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Address"),
      dataIndex: "address",
      key: "address",
      render: (address: string) => t(address),
    },
    {
      title: t("Person In Charge"),
      dataIndex: "personId",
      key: "personId",
      render: (personId: string) => (
        <div>
          <InputChip data={[users[personId]?.name]} />
        </div>
      ),
    },
    {
      title: t("Action"),
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => history.push(`/companies/${companyId}/stores/${id}`)}
          />

          <Popconfirm
            title={t("Are you sure to delete this store?")}
            onConfirm={() => onDelete(id)}
            cancelText={t("Cancel")}
            okText={t("Confirm")}
          >
            <Tooltip placement="right" title={t("Delete")}>
              <Button danger icon={<DeleteOutlined />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    const getStoreList = async () => {
      setLoading(true);
      try {
        const snapshot = await db
          .collection("Stores")
          .where("companyId", "==", companyId)
          .get();
        const list: any[] = buildResultsDocs(snapshot);
        const promisesUsers: any = [];
        list.map(
          (store) =>
            store.personId &&
            promisesUsers.push(
              db.collection("Admins").doc(store.personId).get()
            )
        );
        const results = await Promise.all(promisesUsers);
        setStores(list);
        setUsers(buildDataFromDocs(results));
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setLoading(false);
      }
    };

    getStoreList();
  }, [companyId]);

  const deleteStore = async (key: string) => {
    try {
      setLoading(true);
      await db.collection("Stores").doc(key).delete();
      setStores((prev: any) => prev.filter((item: any) => item.id !== key));
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className={classes.userHeader}>
        <Typography variant="h5">{t("Manage Stores")}</Typography>
        <div className={classes.addButton}>
          <MaterialButton
            variant="contained"
            color="primary"
            onClick={() =>
              history.push(`/companies/${companyId}/stores/${getStoreId()}`)
            }
          >
            追加
          </MaterialButton>
        </div>
      </div>
      <Paper>
        <Table
          dataSource={stores}
          columns={columns(deleteStore, history, companyId)}
          loading={loading}
        />
      </Paper>
    </div>
  );
};

export const StoreList = withStyles(styles)(_StoreList);
