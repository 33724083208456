/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { JobApplicationConfig } from "./helpers";
import { buildResultsDocs } from "utils";
import firebase from "firebase";
import { FormikProps } from "formik";
import { Company, Job, User } from "components/interfaces";
import { MENU_ITEM_PLACEMENT } from "components";

const { INPUT_VARIANT, TITLE_VARIANT }: any = JobApplicationConfig;
let db = firebase.firestore();

interface Props {
  formik: FormikProps<Job>;
  haveError: any;
}
const PersonInCharge: React.FC<Props> = ({ formik, haveError }: Props) => {
  const { t } = useTranslation();
  const [companyList, setCompanyList] = useState<Company[]>([]);
  const [storeList, setStoreList] = useState<any>([]);
  const [picList, setPicList] = useState<any>([]);

  const fetchCompanies = async () => {
    let docs = await db.collection("Companies").get();
    let companiesList = await buildResultsDocs(docs);
    setCompanyList(companiesList);
  };

  const fetchStoreList = async (companyId: string) => {
    let docs = await db
      .collection("Stores")
      .where("companyId", "==", companyId)
      .get();
    let storesList = await buildResultsDocs(docs);
    setStoreList(storesList);
  };

  const fetchPersonInCharge = async (companyId: string) => {
    let docs = await db
      .collection("Admins")
      .where("companyId", "==", companyId)
      .get();
    let personInChargeList = await buildResultsDocs(docs);
    setPicList(personInChargeList);
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    if (formik.values.companyId?.length > 0) {
      fetchStoreList(formik.values.companyId);
      fetchPersonInCharge(formik.values.companyId);
    }
  }, [formik.values.companyId]);

  const resetDependencyFields = () => {
    formik.setFieldValue("storeId", "");
    formik.setFieldValue("contactPersonsIds", []);
  };
  return (
    <div className="person-in-charge-section">
      <Typography variant={TITLE_VARIANT}>
        {t("Store/Person in charge Registration")}
      </Typography>
      <FormControl variant={INPUT_VARIANT} required>
        <InputLabel>{t("Company Name")}</InputLabel>
        <Select
          name="companyId"
          value={companyList.length === 0 ? "" : formik.values.companyId}
          variant={INPUT_VARIANT}
          MenuProps={MENU_ITEM_PLACEMENT}
          error={haveError("companyId").error}
          onChange={(e) => {
            resetDependencyFields();
            formik.setFieldValue(
              "companyName",
              companyList.find((company: any) => company.id === e.target.value)
                ?.companyName || ""
            );
            formik.setFieldValue(
              "companyNameEn",
              companyList.find((company: any) => company.id === e.target.value)
                ?.companyNameEn || ""
            );
            formik.handleChange(e);
          }}
          label={t("Contact Person name")}
        >
          {companyList.map((company: any) => (
            <MenuItem key={company.companyName} value={company.id}>
              {company.companyName}
            </MenuItem>
          ))}
        </Select>
        {haveError("companyId").message && (
          <FormHelperText error>
            {haveError("companyId").message}
          </FormHelperText>
        )}
      </FormControl>
      <FormControl variant={INPUT_VARIANT} required>
        <InputLabel>{t("Stores")}</InputLabel>
        <Select
          name="storeId"
          MenuProps={MENU_ITEM_PLACEMENT}
          value={storeList.length === 0 ? "" : formik.values.storeId}
          variant={INPUT_VARIANT}
          error={haveError("storeId").error}
          onChange={(e) => {
            const { name, address, nameEn } = storeList.find(
              (store: any) => store.id === e.target.value
            ) as any;
            formik.setFieldValue("storeName", name ?? "");
            formik.setFieldValue("storeNameEn", nameEn ?? "");
            formik.setFieldValue("address", address ?? "");
            formik.handleChange(e);
          }}
          label={t("Stores")}
        >
          {storeList.map((store: any) => (
            <MenuItem key={store.id} value={store.id}>
              {store.name}
            </MenuItem>
          ))}
        </Select>
        {haveError("storeId").message && (
          <FormHelperText error>{haveError("storeId").message}</FormHelperText>
        )}
      </FormControl>
      <FormControl variant={INPUT_VARIANT} required>
        <InputLabel id="demo-simple-select-outlined-label">
          {t("Contact Person name")}
        </InputLabel>
        <Select
          multiple
          MenuProps={MENU_ITEM_PLACEMENT}
          value={formik.values.contactPersonsIds}
          name="contactPersonsIds"
          error={haveError("contactPersonsIds").error}
          variant={INPUT_VARIANT}
          onChange={formik.handleChange}
          renderValue={(selected) => (
            <>
              {(selected as string[]).map((value) => (
                <Chip
                  className="chip"
                  key={value}
                  label={picList.find((item: any) => item.id === value)?.name}
                />
              ))}
            </>
          )}
          label={t("Contact Person Name")}
        >
          {picList.map((user: User) => (
            <MenuItem key={user?.id || ""} value={user?.id || ""}>
              {user.name}
            </MenuItem>
          ))}
        </Select>
        {haveError("contactPersonsIds").message && (
          <FormHelperText error>
            {haveError("contactPersonsIds").message}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

export { PersonInCharge };
