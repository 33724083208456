import React from "react";
import { Select } from "antd";
import styled from "styled-components";
import { theme } from "../../theme";
import Arrow from "../../assets/icons/arrow.svg";
import {
  CaretDownFilled,
  DownOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

export interface ISelect {
  showSearch?: boolean;
  disabled?: boolean;
  width?: string;
  height?: string;
  className?: string;
  admin?: boolean;
  radius?: string;
  onBlur?: any;
  clear?: boolean;
  defaultValue?: string | string[] | number | number[];
  options?: any[];
  value?: any;
  onChange?: any;
  suffix?: string;
  type?: "optgroup";
  dark?: boolean;
  onFocus?: any;
  placeholder?: string;
  error?: any;
  name?: any;
  bgColor?: string;
  required?: boolean;
  label?: string;
  loading?: boolean;
  filterOption?: (inputValue: any, option: any) => boolean | boolean;
}

const SelectWithStyle = styled(Select)`
  width: ${({ width }: ISelect) => {
    return width ? `${width} !important` : "auto";
  }};
  & .ant-select-selector {
    border-radius: ${({ radius }: ISelect) => {
      return radius ? `${radius} !important` : "4px !important";
    }};
    border-color: ${({ error }: ISelect) => {
      return error ? `${theme.alert} !important` : `${theme.border} !important`;
    }};
    min-height: ${({ height }: ISelect) => {
      return height ? `${height} !important` : "46px";
    }};
    width: ${({ width }: ISelect) => {
      return width ? `${width} !important` : "auto";
    }};
    background-color: ${({ bgColor, dark }: ISelect) => {
      return bgColor
        ? `${bgColor} !important`
        : dark
        ? `${theme.lightGrey} !important`
        : theme.base;
    }};
  }
  & .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
  & .ant-select-selection-item,
  .ant-select-selection-placeholder {
    min-height: ${({ admin }: ISelect) => {
      return admin ? "40px" : "46px";
    }};
    vertical-align: middle;
    display: flex;
    align-items: center;
    ${{ ...theme.typography.Normal }}
  }
`;

const WrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const Error = styled.div`
  color: ${theme.alert};
`;
const RequiredLabel = styled.div`
  ${theme.typography.PackDesc};
  line-height: 20px;
  color: #f5222d;
  min-width: 40px;
  display: flex;
  justify-content: center;
  height: 22px;
  background: #fff1f0;
  border: 1px solid #ffa39e;
  border-radius: 2px;
`;
const LabelContainer = styled.div`
  display: flex;
  margin-bottom: 2px;
`;
const Label = styled.div`
  ${theme.typography.PackDesc};
  margin-right: 10px;
`;

const StyledCaretDownFilled = styled(CaretDownFilled)`
  color: ${theme.black};
`;
const SelectComponent = (props: ISelect) => {
  const { Option, OptGroup } = Select;
  const {
    options,
    clear,
    type,
    suffix,
    loading,
    admin,
    label,
    required,
    showSearch,
    filterOption,
    ...rest
  } = props;
  return (
    <WrapperDiv>
      {label && (
        <LabelContainer>
          <Label>{label}</Label>
          {required && <RequiredLabel>必須</RequiredLabel>}
        </LabelContainer>
      )}
      <SelectWithStyle
        allowClear={clear}
        admin={admin}
        showSearch={showSearch}
        filterOption={filterOption}
        suffixIcon={() =>
          loading ? (
            <LoadingOutlined />
          ) : suffix === "caretdown" ? (
            <StyledCaretDownFilled />
          ) : admin ? (
            <DownOutlined />
          ) : (
            <Arrow />
          )
        }
        {...rest}
      >
        {type === "optgroup"
          ? options &&
            options.map(
              (option: any, idx: number) =>
                option.optname && (
                  <OptGroup key={idx} label={option.optname}>
                    {option.values &&
                      option.values.map((option: any, index: number) => (
                        <Option key={`${idx}-${index}`} value={option.value}>
                          {option.name}
                        </Option>
                      ))}
                  </OptGroup>
                )
            )
          : options &&
            options.map((option: any, index: number) =>
              typeof option == "object" ? (
                <Option key={index} value={option.value}>
                  {option.name}
                </Option>
              ) : (
                <Option key={index} value={option}>
                  {option}
                </Option>
              )
            )}
      </SelectWithStyle>
      {props.error && <Error>{props.error}</Error>}
    </WrapperDiv>
  );
};

export { SelectComponent };
