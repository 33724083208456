import React, { useContext, useEffect, useState } from "react";
import firebase from "../../firebase";
import Paper from "@material-ui/core/Paper";
import { Table } from "antd";
import "antd/dist/antd.css";
import Link from "@material-ui/core/Link";
import { createStyles, WithStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import { DashBoardContext } from "pages/DashboardLayout/DashboardContext";
import DashboardLayout from "pages/DashboardLayout/Dashboard";
import { useTranslation } from "react-i18next";
import { catchExceptionCallback } from "utils";

const db = firebase.firestore();

const categoryList: { [key: string]: string } = {
  "finding-job": "Finding a Job",
  "resumes-cover-letters": "Resumes & Cover Letters",
  interviewing: "Interviewing",
  "starting-job": "Starting a New Job",
};

interface Props extends WithStyles<typeof styles> {}

const styles = createStyles({
  add: {
    padding: "25px 80px 20px 20px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row-reverse",
  },
  text: {
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: 500,
  },
  addButton: {
    padding: "10px 20px 10px 20px",
    backgroundColor: "#39404F",
    borderRadius: "5px",
    border: "1px solid #39404F",
    cursor: "pointer",
    fontSize: "13px",
    color: "#FFFFFF",
    lineHeight: "20px",
    fontWeight: 500,
  },
});

function List(props: Props) {
  const { classes } = props;

  const history = useHistory();

  const { setCurrentTitle } = useContext(DashBoardContext);
  const [articleList, setArticleList] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setCurrentTitle("記事一覧");
    getCompanyList();
  }, [setCurrentTitle]);

  const getCompanyList = async () => {
    let query = db.collection("Articles").orderBy("updatedAt", "desc");
    try {
      setLoading(true);
      const spanShot = await query.get();
      const list: any[] = [];
      spanShot.docs.forEach((doc) => {
        const data = doc.data();
        data.id = doc.id;
        list.push(data);
      });
      setArticleList(list);
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setLoading(false);
    }
  };

  // dummy datas for the dummy purpose of this project
  const columns = [
    {
      title: "タイトル",
      dataIndex: "title",
      key: "タイトル",
      render: (title: string, article: any) => (
        <Link href={`/article/${article.id}`}>{title || article.titleEn}</Link>
      ),
    },
    {
      title: "カテゴリー",
      dataIndex: "category",
      key: "カテゴリー",
      render: (category: string) => categoryList[category],
    },
    {
      title: t("URL"),
      dataIndex: "url",
      key: "URL",
      render: (_: unknown, article: any) => (
        <Link
          href={`https://quickjobsjapan.com/career-guide/${article.category}/${article.id}`}
        >
          {article.id}
        </Link>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <div className={classes.add}>
        <div>
          <button
            onClick={() => history.push("/articles/add")}
            className={classes.addButton}
          >
            追加
          </button>
        </div>
      </div>
      <Paper>
        <Table
          columns={columns}
          dataSource={articleList}
          loading={loading}
          rowKey="id"
          style={{ padding: "0.5em" }}
        />
      </Paper>
    </DashboardLayout>
  );
}

export default withStyles(styles)(List);
