import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { ImageUpload, TagGroup } from "components/Elements";
import { Job } from "components/interfaces";
import { FieldArray, FormikProps, FormikProvider } from "formik";
import moment from "moment";
import { Typography } from "antd";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  FORM_LEVEL_ERROR_MAP,
  INITIAL_VALUES,
  JobApplicationConfig,
  MAX_TEXT_LENGTH,
  MAX_JOB_DESCRIPTION_LENGTH,
  TAG_SELECTION,
  MAX_JOB_TITLE_LENGTH,
} from "./helpers";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const { INPUT_VARIANT } = JobApplicationConfig as any;
const { Text } = Typography;
interface Props {
  formik: FormikProps<Job>;
  mode: "english" | "japanese";
  haveError: any;
}
export const JobListingBody: React.FC<Props> = ({
  formik,
  mode,
  haveError,
}: Props) => {
  const { t } = useTranslation();

  const getFormLevelError = (mode: "english" | "japanese") => {
    let _formik = formik as any;
    const ENGLISH_KEYS = ["titleEn", "jobDescriptionEn"];
    const JAPANESE_KEYS = ["title", "jobDescription"];
    let KEYS: string[] = [];
    if (mode === "english") KEYS = ENGLISH_KEYS;
    else KEYS = JAPANESE_KEYS;
    let errorKeys: string[] = [];
    KEYS.forEach((key: string) => {
      if (_formik.errors[key] && _formik.touched[key]) errorKeys.push(key);
    });

    if (
      _formik.errors.workingHours &&
      Array.isArray(_formik.errors.workingHours)
    ) {
      _formik.errors.workingHours.forEach((object: any, index: any) => {
        if (!object) return;
        Object.keys(object).forEach((key) => {
          if (
            !_formik.touched.workingHours ||
            !_formik.touched.workingHours[index]
          )
            return;
          if (_formik.touched.workingHours[index][key]) errorKeys.push(key);
        });
      });
    }
    let missingLabels: string[] = [];
    errorKeys
      .filter((item) =>
        mode === "english" ? item.includes("En") : !item.includes("En")
      )
      .forEach((key) => {
        let label = FORM_LEVEL_ERROR_MAP[key] || "Hourly wage table";
        if (!missingLabels.includes(label)) missingLabels.push(label);
      });
    return missingLabels.length > 0
      ? `Values of: ${missingLabels.join(
          ", "
        )} are missing from ${mode} section form`
      : "";
  };

  useEffect(() => {
    formik.values.workingHours.forEach((_, index) => {
      if (formik.values.employmentType === "fullTime") {
        formik.setFieldValue(`workingHours.${index}.maxHourlyWageEn`, "");
        formik.setFieldValue(`workingHours.${index}.maxHourlyWage`, "");
        formik.setFieldValue(`workingHours.${index}.minHourlyWageEn`, "");
        formik.setFieldValue(`workingHours.${index}.minHourlyWage`, "");
      } else {
        formik.setFieldValue(`workingHours.${index}.salary`, "");
        formik.setFieldValue(`workingHours.${index}.salaryEn`, "");
      }
    });
  }, [formik.values.employmentType]);

  const quillRefJobDesc: any = useRef(null);
  const quillRefJobDescEn: any = useRef(null);

  const handleJobDescriptionKeyPress = (e: any, name: string) => {
    let jobDescriptionOnlyCharacters;
    switch (name) {
      case "jobDescription":
        jobDescriptionOnlyCharacters = formik.values.jobDescription;
        break;
      case "jobDescriptionEn":
        jobDescriptionOnlyCharacters = formik.values.jobDescriptionEn;
        break;
    }
    if (!jobDescriptionOnlyCharacters) return;
    jobDescriptionOnlyCharacters = jobDescriptionOnlyCharacters.replace(
      /(<([^>]+)>)/gi,
      ""
    );
    if (jobDescriptionOnlyCharacters?.length > MAX_JOB_DESCRIPTION_LENGTH - 1)
      e.preventDefault();
  };

  const handleJobDescriptionChange = (
    value: string,
    editor: any,
    name: string,
    source: string
  ) => {
    if (source !== "user") return;
    let quillRef;
    switch (name) {
      case "jobDescription":
        quillRef = quillRefJobDesc;
        break;
      case "jobDescriptionEn":
        quillRef = quillRefJobDescEn;
        break;
    }
    if (!quillRef) return;
    let content = value;
    if (editor.getLength() > MAX_JOB_DESCRIPTION_LENGTH) {
      quillRef.current
        .getEditor()
        .deleteText(MAX_JOB_DESCRIPTION_LENGTH, editor.getLength());
      content = quillRef.current.getEditor().root?.innerHTML;
    }
    if (content.replace(/(<([^>]+)>)/gi, "").length === 0) content = "";
    formik.setFieldValue(name, content);
  };

  return (
    <>
      {mode === "japanese" ? (
        <>
          <Grid container>
            <Grid item md={12} lg={12}>
              <FormControl variant={INPUT_VARIANT} id="title">
                <TextField
                  variant={INPUT_VARIANT}
                  label={t("Job Title")}
                  name="title"
                  value={formik.values.title}
                  inputProps={{ maxLength: MAX_JOB_TITLE_LENGTH }}
                  onChange={formik.handleChange}
                  error={haveError("title").error}
                  helperText={haveError("title").message}
                />
              </FormControl>
              <FormControl variant={INPUT_VARIANT} id="address">
                <TextField
                  variant={INPUT_VARIANT}
                  label={t("Address")}
                  name="addressJa"
                  value={formik.values.addressJa}
                  inputProps={{ maxLength: 50 }}
                  onChange={formik.handleChange}
                  error={haveError("addressJa").error}
                  helperText={haveError("addressJa").message}
                />
              </FormControl>
              <FormControl id="jobDescription">
                <ReactQuill
                  ref={quillRefJobDesc}
                  className={
                    haveError("jobDescription").error
                      ? "custom-error-class Mui-error"
                      : ""
                  }
                  placeholder={t("Job Description")}
                  value={formik.values.jobDescription}
                  onChange={(value, delta, source, editor) => {
                    handleJobDescriptionChange(
                      value,
                      editor,
                      "jobDescription",
                      source
                    );
                  }}
                  onKeyPress={(e) => {
                    handleJobDescriptionKeyPress(e, "jobDescription");
                  }}
                />
                {haveError("jobDescription").error && (
                  <p className="custom-class-error-message">
                    {haveError("jobDescription").message}
                  </p>
                )}
              </FormControl>
            </Grid>
          </Grid>
          {/* Hourly wage/work hours */}

          <FormikProvider value={formik}>
            {/* Header */}
            <InputLabel>{t("Hourly wage/work hours")}</InputLabel>
            <Card className="card-body" id="workingHours">
              <Grid container xs={12} spacing={2}>
                <Grid item xs={2}>
                  {t("Job Type")}
                </Grid>
                <Grid item xs={5}>
                  {t("Work Hours")}
                </Grid>
                {formik.values.employmentType !== "fullTime" ? (
                  <>
                    <Grid item xs={2}>
                      {t("Min Salary")}
                    </Grid>
                    <Grid item xs={2}>
                      {t("Max Salary")}
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={4}>
                    {t("Salary")}
                  </Grid>
                )}
                <Grid item xs={1}></Grid>

                {/* Body */}
                <FieldArray
                  name="workingHours"
                  render={(arrayHelpers) => (
                    <div style={{ width: "100%" }}>
                      {formik.values.workingHours.map((item, index) => {
                        return (
                          <Grid container key={index} spacing={1}>
                            <Grid item xs={2}>
                              <FormControl variant={INPUT_VARIANT}>
                                <TextField
                                  variant={INPUT_VARIANT}
                                  inputProps={{ maxLength: MAX_TEXT_LENGTH }}
                                  name={`workingHours.${index}.jobType`}
                                  error={
                                    haveError("workingHours").forArray(
                                      index,
                                      "jobType"
                                    ).error
                                  }
                                  helperText={
                                    haveError("workingHours").forArray(
                                      index,
                                      "jobType"
                                    ).message
                                  }
                                  value={item.jobType}
                                  onChange={formik.handleChange}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={5} className="hourly-wage">
                              <FormControl variant={INPUT_VARIANT}>
                                <TextField
                                  type="time"
                                  variant={INPUT_VARIANT}
                                  name={`workingHours.${index}.from`}
                                  value={
                                    moment(item.from, "hh:mm A").isValid()
                                      ? moment(item.from, "hh:mm A")
                                          .format("HH:mm")
                                          .toString()
                                      : item.from
                                  }
                                  error={
                                    haveError("workingHours").forArray(
                                      index,
                                      "from"
                                    ).error
                                  }
                                  helperText={
                                    haveError("workingHours").forArray(
                                      index,
                                      "from"
                                    ).message
                                  }
                                  onChange={formik.handleChange}
                                />
                              </FormControl>
                              <FormControl variant={INPUT_VARIANT}>
                                <TextField
                                  type="time"
                                  variant={INPUT_VARIANT}
                                  error={
                                    haveError("workingHours").forArray(
                                      index,
                                      "to"
                                    ).error
                                  }
                                  helperText={
                                    haveError("workingHours").forArray(
                                      index,
                                      "to"
                                    ).message
                                  }
                                  name={`workingHours.${index}.to`}
                                  value={
                                    moment(item.to, "hh:mm A").isValid()
                                      ? moment(item.to, "hh:mm A")
                                          .format("HH:mm")
                                          .toString()
                                      : item.to
                                  }
                                  onChange={formik.handleChange}
                                />
                              </FormControl>
                            </Grid>
                            {formik.values.employmentType !== "fullTime" ? (
                              <>
                                <Grid item xs={2} className="hourly-wage">
                                  <FormControl variant={INPUT_VARIANT}>
                                    <TextField
                                      variant={INPUT_VARIANT}
                                      inputProps={{
                                        maxLength: MAX_TEXT_LENGTH,
                                      }}
                                      name={`workingHours.${index}.minHourlyWage`}
                                      error={
                                        haveError("workingHours").forArray(
                                          index,
                                          "minHourlyWage"
                                        ).error
                                      }
                                      helperText={
                                        haveError("workingHours").forArray(
                                          index,
                                          "minHourlyWage"
                                        ).message
                                      }
                                      value={item.minHourlyWage}
                                      onChange={formik.handleChange}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={2} className="hourly-wage">
                                  <FormControl variant={INPUT_VARIANT}>
                                    <TextField
                                      variant={INPUT_VARIANT}
                                      inputProps={{
                                        maxLength: MAX_TEXT_LENGTH,
                                      }}
                                      name={`workingHours.${index}.maxHourlyWage`}
                                      error={
                                        haveError("workingHours").forArray(
                                          index,
                                          "maxHourlyWage"
                                        ).error
                                      }
                                      helperText={
                                        haveError("workingHours").forArray(
                                          index,
                                          "maxHourlyWage"
                                        ).message
                                      }
                                      value={item.maxHourlyWage}
                                      onChange={formik.handleChange}
                                    />
                                  </FormControl>
                                </Grid>
                              </>
                            ) : (
                              <Grid item xs={4} className="hourly-wage">
                                <FormControl variant={INPUT_VARIANT}>
                                  <TextField
                                    variant={INPUT_VARIANT}
                                    inputProps={{ maxLength: MAX_TEXT_LENGTH }}
                                    name={`workingHours.${index}.salary`}
                                    error={
                                      haveError("workingHours").forArray(
                                        index,
                                        "salary"
                                      ).error
                                    }
                                    helperText={
                                      haveError("workingHours").forArray(
                                        index,
                                        "salary"
                                      ).message
                                    }
                                    value={item.salary}
                                    onChange={formik.handleChange}
                                  />
                                </FormControl>
                              </Grid>
                            )}
                            <Grid item xs={1}>
                              <IconButton
                                color="primary"
                                disabled={
                                  formik.values.workingHours.length <= 1
                                }
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                            <Grid item xs={11}>
                              <FormControl variant={INPUT_VARIANT}>
                                <TextField
                                  placeholder={t("Comments")}
                                  variant={INPUT_VARIANT}
                                  inputProps={{ maxLength: 100 }}
                                  name={`workingHours.${index}.comment`}
                                  error={
                                    haveError("workingHours").forArray(
                                      index,
                                      "comment"
                                    ).error
                                  }
                                  helperText={
                                    haveError("workingHours").forArray(
                                      index,
                                      "comment"
                                    ).message
                                  }
                                  value={item.comment}
                                  onChange={formik.handleChange}
                                />
                              </FormControl>
                            </Grid>
                            <Grid xs={1} />
                          </Grid>
                        );
                      })}
                      {/* Footer */}
                      <div className="align-center">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            arrayHelpers.push(INITIAL_VALUES.workingHours[0])
                          }
                        >
                          {t("Add Row")}
                        </Button>
                      </div>
                    </div>
                  )}
                />
              </Grid>
            </Card>
          </FormikProvider>
        </>
      ) : (
        <>
          <Grid container>
            <Grid item md={12} lg={12}>
              <FormControl variant={INPUT_VARIANT} id="titleEn">
                <TextField
                  variant={INPUT_VARIANT}
                  label={t("Job Title")}
                  name="titleEn"
                  inputProps={{ maxLength: MAX_JOB_TITLE_LENGTH }}
                  error={haveError("titleEn").error}
                  helperText={haveError("titleEn").message}
                  value={formik.values.titleEn}
                  onChange={formik.handleChange}
                />
              </FormControl>
              <FormControl variant={INPUT_VARIANT} id="address">
                <TextField
                  variant={INPUT_VARIANT}
                  label={t("Address")}
                  name="addressEn"
                  value={formik.values.addressEn}
                  inputProps={{ maxLength: 50 }}
                  onChange={formik.handleChange}
                  error={haveError("addressEn").error}
                  helperText={haveError("addressEn").message}
                />
              </FormControl>
              <FormControl id="jobDescriptionEn">
                <ReactQuill
                  ref={quillRefJobDescEn}
                  className={
                    haveError("jobDescriptionEn").error
                      ? "custom-error-class Mui-error"
                      : ""
                  }
                  placeholder={t("Job Description")}
                  value={formik.values.jobDescriptionEn}
                  onChange={(value, delta, source, editor) => {
                    handleJobDescriptionChange(
                      value,
                      editor,
                      "jobDescriptionEn",
                      source
                    );
                  }}
                  onKeyPress={(e) => {
                    handleJobDescriptionKeyPress(e, "jobDescriptionEn");
                  }}
                />
                {haveError("jobDescriptionEn").error && (
                  <p className="custom-class-error-message">
                    {haveError("jobDescriptionEn").message}
                  </p>
                )}
              </FormControl>
            </Grid>
          </Grid>
          {/* Hourly wage/work hours */}
          <FormikProvider value={formik}>
            {/* Header */}
            <InputLabel>{t("Hourly wage/work hours")}</InputLabel>
            <Card className="card-body" id="workingHours">
              <Grid container xs={12} spacing={2}>
                <Grid item xs={2}>
                  {t("Job Type")}
                </Grid>
                <Grid item xs={5}>
                  {t("Work Hours")}
                </Grid>
                {formik.values.employmentType === "fullTime" ? (
                  <Grid item xs={4}>
                    {t("Salary")}
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={2}>
                      {t("Min Salary")}
                    </Grid>
                    <Grid item xs={2}>
                      {t("Max Salary")}
                    </Grid>
                  </>
                )}
                <Grid item xs={1} />
                {/* Body */}
                <FieldArray
                  name="workingHours"
                  render={(arrayHelpers) => (
                    <div style={{ width: "100%" }}>
                      {formik.values.workingHours.map((item, index) => {
                        return (
                          <Grid container key={index} spacing={1}>
                            <Grid item xs={2}>
                              <FormControl variant={INPUT_VARIANT}>
                                <TextField
                                  variant={INPUT_VARIANT}
                                  error={
                                    haveError("workingHours").forArray(
                                      index,
                                      "jobTypeEn"
                                    ).error
                                  }
                                  helperText={
                                    haveError("workingHours").forArray(
                                      index,
                                      "jobTypeEn"
                                    ).message
                                  }
                                  inputProps={{ maxLength: MAX_TEXT_LENGTH }}
                                  name={`workingHours.${index}.jobTypeEn`}
                                  value={item.jobTypeEn}
                                  onChange={formik.handleChange}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={5} className="hourly-wage">
                              <FormControl variant={INPUT_VARIANT}>
                                <TextField
                                  type="time"
                                  variant={INPUT_VARIANT}
                                  name={`workingHours.${index}.from`}
                                  error={
                                    haveError("workingHours").forArray(
                                      index,
                                      "from"
                                    ).error
                                  }
                                  helperText={
                                    haveError("workingHours").forArray(
                                      index,
                                      "from"
                                    ).message
                                  }
                                  value={
                                    moment(item.from, "hh:mm A").isValid()
                                      ? moment(item.from, "hh:mm A")
                                          .format("HH:mm")
                                          .toString()
                                      : item.from
                                  }
                                  onChange={formik.handleChange}
                                />
                              </FormControl>
                              <FormControl variant={INPUT_VARIANT}>
                                <TextField
                                  type="time"
                                  variant={INPUT_VARIANT}
                                  name={`workingHours.${index}.to`}
                                  error={
                                    haveError("workingHours").forArray(
                                      index,
                                      "to"
                                    ).error
                                  }
                                  helperText={
                                    haveError("workingHours").forArray(
                                      index,
                                      "to"
                                    ).message
                                  }
                                  value={
                                    moment(item.to, "hh:mm A").isValid()
                                      ? moment(item.to, "hh:mm A")
                                          .format("HH:mm")
                                          .toString()
                                      : item.to
                                  }
                                  onChange={formik.handleChange}
                                />
                              </FormControl>
                            </Grid>
                            {formik.values.employmentType !== "fullTime" ? (
                              <>
                                <Grid item xs={2} className="hourly-wage">
                                  <FormControl variant={INPUT_VARIANT}>
                                    <TextField
                                      variant={INPUT_VARIANT}
                                      name={`workingHours.${index}.minHourlyWageEn`}
                                      inputProps={{
                                        maxLength: MAX_TEXT_LENGTH,
                                      }}
                                      error={
                                        haveError("workingHours").forArray(
                                          index,
                                          "minHourlyWageEn"
                                        ).error
                                      }
                                      helperText={
                                        haveError("workingHours").forArray(
                                          index,
                                          "minHourlyWageEn"
                                        ).message
                                      }
                                      value={item.minHourlyWageEn}
                                      onChange={formik.handleChange}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={2} className="hourly-wage">
                                  <FormControl variant={INPUT_VARIANT}>
                                    <TextField
                                      variant={INPUT_VARIANT}
                                      inputProps={{
                                        maxLength: MAX_TEXT_LENGTH,
                                      }}
                                      name={`workingHours.${index}.maxHourlyWageEn`}
                                      error={
                                        haveError("workingHours").forArray(
                                          index,
                                          "maxHourlyWageEn"
                                        ).error
                                      }
                                      helperText={
                                        haveError("workingHours").forArray(
                                          index,
                                          "maxHourlyWageEn"
                                        ).message
                                      }
                                      value={item.maxHourlyWageEn}
                                      onChange={formik.handleChange}
                                    />
                                  </FormControl>
                                </Grid>
                              </>
                            ) : (
                              <Grid item xs={4} className="hourly-wage">
                                <FormControl variant={INPUT_VARIANT}>
                                  <TextField
                                    variant={INPUT_VARIANT}
                                    inputProps={{ maxLength: MAX_TEXT_LENGTH }}
                                    name={`workingHours.${index}.salaryEn`}
                                    error={
                                      haveError("workingHours").forArray(
                                        index,
                                        "salaryEn"
                                      ).error
                                    }
                                    helperText={
                                      haveError("workingHours").forArray(
                                        index,
                                        "salaryEn"
                                      ).message
                                    }
                                    value={item.salaryEn}
                                    onChange={formik.handleChange}
                                  />
                                </FormControl>
                              </Grid>
                            )}
                            <Grid item xs={1}>
                              <IconButton
                                color="primary"
                                disabled={
                                  formik.values.workingHours.length <= 1
                                }
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                            <Grid item xs={11}>
                              <FormControl variant={INPUT_VARIANT}>
                                <TextField
                                  placeholder={t("Comments")}
                                  variant={INPUT_VARIANT}
                                  inputProps={{ maxLength: MAX_TEXT_LENGTH }}
                                  error={
                                    haveError("workingHours").forArray(
                                      index,
                                      "commentEn"
                                    ).error
                                  }
                                  helperText={
                                    haveError("workingHours").forArray(
                                      index,
                                      "commentEn"
                                    ).message
                                  }
                                  name={`workingHours.${index}.commentEn`}
                                  value={item.commentEn}
                                  onChange={formik.handleChange}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        );
                      })}
                      {/* Footer */}
                      <div className="align-center">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            arrayHelpers.push(INITIAL_VALUES.workingHours[0])
                          }
                        >
                          {t("Add Row")}
                        </Button>
                      </div>
                    </div>
                  )}
                />
              </Grid>
            </Card>
          </FormikProvider>
        </>
      )}

      <Grid container>
        <Text type="danger">
          {getFormLevelError(mode === "english" ? "japanese" : "english")}
        </Text>
      </Grid>

      <FormControl variant={INPUT_VARIANT}>
        <FormLabel className="image-label">{t("Image Import")}</FormLabel>
        <ImageUpload
          value={formik.values.imageFile}
          previewValue={formik.values.imagePreviewUrl}
          handleChange={(value) => {
            formik.setFieldValue("imagePreviewUrl", value.imagePreviewUrl);
            formik.setFieldValue("imageFile", value.imageFile);
          }}
        >
          <Button
            variant={INPUT_VARIANT}
            color="default"
            startIcon={<CloudUpload />}
          >
            {t("Upload")}
          </Button>
        </ImageUpload>
      </FormControl>
      {/* Tag Selection */}
      <InputLabel>{t("Tag selection")}</InputLabel>
      <TagGroup
        list={TAG_SELECTION.welcome}
        selected={formik.values.welcomeTags}
        onChange={(e) => formik.setFieldValue("welcomeTags", e)}
        label={t("Welcome")}
      />
      <TagGroup
        list={TAG_SELECTION.workingHours}
        selected={formik.values.workingHoursTags}
        label={t("Working Hours")}
        onChange={(e) => formik.setFieldValue("workingHoursTags", e)}
      />
      <TagGroup
        list={TAG_SELECTION.others}
        selected={formik.values.othersTags}
        label={t("Others")}
        onChange={(e) => formik.setFieldValue("othersTags", e)}
      />
      <TagGroup
        list={TAG_SELECTION.experience}
        selected={formik.values.experienceTags}
        label={t("Experience")}
        onChange={(e) => formik.setFieldValue("experienceTags", e)}
      />

      {/* Travel expense */}
      <FormControl
        component="fieldset"
        className="resident-status-checkbox-group"
      >
        <FormLabel component="legend">{t("Travel expense")}</FormLabel>
        <RadioGroup
          name="travelExpenses"
          value={formik.values.travelExpenses}
          onChange={formik.handleChange}
        >
          {[
            { label: "Provided", value: "yes" },
            { label: "Not Provided", value: "no" },
          ].map((item) => (
            <FormControlLabel
              key={item.value}
              value={item.value}
              control={<Radio />}
              label={t(item.label)}
            />
          ))}
        </RadioGroup>
        {haveError("travelExpenses").message && (
          <FormHelperText error>
            {haveError("travelExpenses").message}
          </FormHelperText>
        )}
      </FormControl>

      {formik.values.travelExpenses === "yes" &&
        (mode === "english" ? (
          <FormControl>
            <TextField
              variant={INPUT_VARIANT}
              value={formik.values.travelExpensesNoteEn ?? ""}
              name="travelExpensesNoteEn"
              onChange={formik.handleChange}
              label={t("Travel Expense Note")}
            />
          </FormControl>
        ) : (
          <FormControl>
            <TextField
              variant={INPUT_VARIANT}
              value={formik.values.travelExpensesNote}
              name="travelExpensesNote"
              onChange={formik.handleChange}
              label={t("Travel Expense Note")}
            />
          </FormControl>
        ))}

      {haveError("travelExpenses").message && (
        <FormHelperText error>
          {haveError("travelExpenses").message}
        </FormHelperText>
      )}
    </>
  );
};
