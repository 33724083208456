import React, { useEffect, useState, useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import firebase from "../../firebase";
import Paper from "@material-ui/core/Paper";
import { Button, Space, Table, Switch, Modal } from "antd";
import MaterialButton from "@material-ui/core/Button";
import "antd/dist/antd.css";
import { createStyles, WithStyles } from "@material-ui/styles";
import { FormControl, TextField, withStyles } from "@material-ui/core";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { EditOutlined } from "@material-ui/icons";
import {
  buildDataFromDocs,
  buildResultsDocs,
  catchExceptionCallback,
} from "utils";
import { useTranslation } from "react-i18next";
import { DashBoardContext } from "pages/DashboardLayout/DashboardContext";
import DashboardLayout from "pages/DashboardLayout/Dashboard";
import InputChip from "components/InputChip";

const db = firebase.firestore();
interface Props extends WithStyles<typeof styles> {}
const styles = createStyles({
  searchWrapper: {
    border: "2px solid #DEDBD5",
    borderRadius: "10px",
    margin: "25px 40px 25px 40px",
    padding: "30px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  add: {
    padding: "25px 80px 20px 20px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#F8F5EF",
    borderRadius: "5px",
  },
  text: {
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: 500,
  },
  addButton: {
    padding: "10px 20px 10px 20px",
    backgroundColor: "#39404F",
    borderRadius: "5px",
    border: "1px solid #39404F",
    cursor: "pointer",
    fontSize: "13px",
    color: "#FFFFFF",
    lineHeight: "20px",
    fontWeight: 500,
  },
});

function List(props: Props) {
  const { classes } = props;
  const { setCurrentTitle } = useContext(DashBoardContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [keyword, setKeyword] = useState("");
  const [searchKeywordValue, setSearchKeyword] = useState("");
  const [companyList, setCompanyList] = useState([] as any[]);
  const [personInCharges, setPersonIncharges] = useState<any>({});
  const { t } = useTranslation();

  const columns = (onDelete: any) => [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "企業名",
      dataIndex: "companyName",
      key: "企業名",
    },
    {
      title: t("Website URL"),
      dataIndex: "websiteURL",
      key: "電話番号",
      render: (data: string) => (
        <Link to={data} target="_blank">
          {data}
        </Link>
      ),
    },
    {
      title: "メールアドレス",
      dataIndex: "personInCharge",
      key: "メールアドレス",
      render: (personInCharge: string[]) => {
        const inchargeId = personInCharge.find(
          (uId) => typeof personInCharges[uId] !== "undefined"
        );
        return (
          inchargeId && <InputChip data={[personInCharges[inchargeId]?.name]} />
        );
      },
    },
    {
      title: "状態",
      dataIndex: "isSuspended",
      key: "状態",
      render: (_: unknown, company: any) => (
        <Switch
          checked={!company.isSuspended}
          onChange={() => onStatusChange(company.id, company.isSuspended)}
        />
      ),
    },
    {
      title: "電話番号",
      dataIndex: "tel",
      key: "電話番号",
      render: (data: string[]) => {
        return Array.isArray(data) ? data.join(",") : data;
      },
    },
    {
      dataIndex: "id",
      key: "action",
      render: (id: string) => (
        <Space>
          <Link to={`/companies/${id}`}>
            <Button icon={<EditOutlined />} />
          </Link>
        </Space>
      ),
    },
  ];

  const getCompanyList = useCallback(async () => {
    try {
      setLoading(true);
      let query: any = db.collection("Companies");

      const spanShot = await query.get();
      let companiesList = buildResultsDocs(spanShot);
      const promisePersonIncharges: any = [];
      companiesList.map((company: any) =>
        (company?.personInCharge || []).map((uId: string) =>
          promisePersonIncharges.push(db.collection("Admins").doc(uId).get())
        )
      );
      const results = await Promise.all(promisePersonIncharges);
      if (keyword && keyword.length > 0) {
        companiesList = companiesList.filter((item: any) => {
          if (
            item.companyName
              .toLowerCase()
              .includes(keyword.trim().toLowerCase())
          )
            return true;
          return false;
        });
      }
      setPersonIncharges(buildDataFromDocs(results));
      setCompanyList(companiesList);
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setLoading(false);
    }
  }, [keyword]);

  const onDelete = (key: string) => {
    db.collection("Companies")
      .doc(key)
      .delete()
      .then(() => {
        setCompanyList(() => companyList.filter((item) => item.id !== key));
      });
  };
  const onStatusChange = (id: string, status: boolean) => {
    Modal.confirm({
      title: t("Confirm"),
      async onOk() {
        try {
          setLoading(true);
          await db.collection("Companies").doc(id).update({
            isSuspended: !status,
          });
          var newCompanyList = companyList.map((item) => {
            if (item.id === id) {
              item.isSuspended = !status;
            }
            return item;
          });
          setCompanyList(newCompanyList);
        } catch (error) {
          catchExceptionCallback(error);
        } finally {
          setLoading(false);
        }
      },
      icon: <ExclamationCircleOutlined />,
      content: !status
        ? t("Are you sure to suspend this company?")
        : t("Are you sure to activate this company?"),
      okText: "确认",
      cancelText: "取消",
    });
  };

  useEffect(() => {
    setCurrentTitle("企業");
    getCompanyList();
  }, [getCompanyList, setCurrentTitle]);

  const handleSearch = () => {
    setKeyword(searchKeywordValue);
  };

  return (
    <DashboardLayout>
      <div className={classes.searchWrapper}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch();
          }}
        >
          <p style={{ margin: "auto 0", paddingRight: "0.5rem" }}>キーワード</p>
          <FormControl
            variant="outlined"
            style={{ minWidth: "240px" }}
            component={"div"}
          >
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={searchKeywordValue}
              name="keyword"
              placeholder="企業名から検索"
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
          </FormControl>
          <MaterialButton
            color="primary"
            type="submit"
            variant="contained"
            disabled={loading ? true : false}
            style={{
              margin: "auto 0",
              marginLeft: "1.3rem",
              marginTop: "0.5rem",
            }}
          >
            探す
          </MaterialButton>
        </form>
        <div>
          <button
            onClick={() => history.push("/companies/add")}
            className={classes.addButton}
          >
            追加
          </button>
        </div>
      </div>
      <Paper>
        <Table
          columns={columns(onDelete)}
          dataSource={companyList}
          loading={loading}
          rowKey="id"
          style={{ padding: "0.5em" }}
        />
      </Paper>
    </DashboardLayout>
  );
}

export default withStyles(styles)(List);
