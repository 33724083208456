import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Brand from "../../assets/images/brand.png";
import { AuthContext } from "AuthProvider";
import { Col, Layout, Menu, Row } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  BankOutlined,
  UsergroupAddOutlined,
  LoginOutlined,
  BookOutlined,
  UserOutlined,
  ContactsOutlined,
} from "@ant-design/icons";
import { DashBoardContext } from "./DashboardContext";

const { Header, Sider, Content } = Layout;

const LogoContainer = styled.div`
  text-align: center;
  display: flex;
  background-color: #e26e30;
  padding: 2em;
`;

const PageHeader = styled.h3`
  margin-left: 2em;
  color: black;
  font-weight: 500;
`;

const DashboardLayout: React.FC = ({ children }) => {
  const {
    loading,
    authenticated,
    logout: _logout,
    role,
  } = useContext(AuthContext);

  const { currentNavbar, setCurrentNavbar, routes, currentTitle } =
    useContext(DashBoardContext);

  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);

  const navItems = [
    {
      key: "求人",
      icon: <UsergroupAddOutlined />,
      label: "求人",
      path: "/jobs",
      function: () => history.push("/jobs"),
    },
    {
      key: "企業",
      icon: <BankOutlined />,
      label: "企業",
      path: "/companies",
      function: () => history.push("/companies"),
    },
    {
      key: "記事一覧",
      icon: <BookOutlined />,
      label: "記事一覧",
      path: "/articles",
      function: () => history.push("/articles"),
    },
    {
      key: "ユーザー",
      admin: true,
      icon: <UserOutlined />,
      label: "会員登録者",
      path: "/users",
      function: () => history.push("/users"),
    },
    {
      key: "管理者",
      admin: true,
      icon: <SettingOutlined />,
      label: "管理者",
      path: "/admins",
      function: () => history.push("/admins"),
    },
    {
      key: "応募者",
      icon: <ContactsOutlined />,
      label: "応募者",
      path: "/applicants",
      function: () => history.push("/applicants"),
    },
    {
      key: "ログアウト",
      icon: <LoginOutlined />,
      label: "ログアウト",
      function: () => {
        setCurrentNavbar("求人");
        _logout();
      },
    },
  ];

  useEffect(() => {
    const url = window.location.pathname;
    const path = `/${url.split("/")[1]}`;
    setCurrentNavbar(
      navItems.filter((item) => item.path === path)[0]?.key || "求人"
    );
  }, [navItems, setCurrentNavbar]);

  if (loading || !authenticated) {
    return null;
  }

  const handleNavigation = (e: any) => {
    setCurrentNavbar(e.key);
    const item = navItems.filter((item) => item.key === e.key)[0];
    item.function();
  };

  return (
    <>
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{ height: "auto", minHeight: "100vh", overflow: "hidden" }}
        >
          <LogoContainer>
            {!collapsed && (
              <img src={Brand} alt="brand logo" style={{ height: "1.5em" }} />
            )}
          </LogoContainer>
          <Menu
            theme="light"
            mode="inline"
            selectedKeys={[`${currentNavbar}`]}
            style={{
              height: "100%",
              backgroundColor: "#39404f",
              color: "#FFFFFF",
            }}
            onClick={(e: any) => handleNavigation(e)}
            items={
              role !== "owner"
                ? navItems.filter((item) => !item.admin)
                : navItems
            }
          />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{ padding: 0, background: "#F8F5EF" }}
          >
            <Row>
              <Col span={1}>
                <div>
                  {React.createElement(
                    collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                    {
                      className: "trigger",
                      style: {
                        color: "black",
                        fontSize: "20px",
                        paddingLeft: "1em",
                      },
                      onClick: () => setCollapsed(!collapsed),
                    }
                  )}
                </div>
              </Col>
              <Col span={10}>
                <div>
                  <PageHeader>{routes[currentTitle]}</PageHeader>
                </div>
              </Col>
              <Col span={1} offset={12}></Col>
            </Row>
          </Header>
          <Content
            className=""
            style={{
              backgroundColor: "#FFFFFF",
              padding: 24,
              minHeight: 280,
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default DashboardLayout;
