import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { api } from "../../config/firebase";
import { axiosConfig } from "../../auth";
import Form from "./Form";
import { catchExceptionCallback } from "utils";
import DashboardLayout from "pages/DashboardLayout/Dashboard";

function Edit() {
  const { id }: any = useParams();

  const [admin, setAdmin] = useState({});

  useEffect(() => {
    const getAdmin = async () => {
      try {
        const response = await axios.post(
          `${api.host}/admin/account/get/${id}`,
          null,
          await axiosConfig()
        );
        const admin = response.data;
        admin.password = "";
        setAdmin(admin);
      } catch (error) {
        catchExceptionCallback(error);
      }
    };
    getAdmin();
  }, [id]);
  if (Object.keys(admin).length === 0) {
    return null;
  }

  if (Object.keys(admin).length > 0) {
    return (
      <DashboardLayout>
        <Form adminData={admin} />
      </DashboardLayout>
    );
  }

}

export default Edit;
