import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface Props {
  options: Array<{ label: string; value: any }>;
  value: string[];
  label: string;
  onChange: (e: any) => void;
}
const CheckboxGroup: React.FC<Props> = ({
  label,
  options,
  value,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let data = value;
    if (e.target.checked) data.push(e.target.name);
    else data = data.filter((item) => item !== e.target.name);
    onChange(data);
  };
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup className="formGroup">
        {options.map((item: any) => (
          <FormControlLabel
            key={item.label}
            value={item.value}
            control={
              <Checkbox
                name={item.value}
                checked={value.filter((ite) => ite === item.value).length > 0}
                onChange={handleChange}
              />
            }
            label={t(item.label)}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

interface Option {
  label: string;
  value: string;
}
export { CheckboxGroup };
