import DashboardLayout from "pages/DashboardLayout/Dashboard";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Detail from "./detail";

interface ApplicationProp extends RouteComponentProps<{ id: string }> {}

const Application: React.FC<ApplicationProp> = (props: ApplicationProp) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  return (
    <DashboardLayout>
      <Detail id={id} />
    </DashboardLayout>
  );
};

export { Application };
