import DashboardLayout from "pages/DashboardLayout/Dashboard";
import React from "react";
import Form from "./Form";

function Add() {
  return (
    <DashboardLayout>
      <Form />
    </DashboardLayout>
  );
}

export default Add;
