import React, { useEffect, useState } from "react";
import firebase from "./firebase";
import "firebase/auth";
import { useHistory } from "react-router-dom";
import { Spin } from "antd";
import "./assets/styles/style.css";

type ContextProps = {
  loading: boolean;
  user: firebase.User | null;
  authenticated: boolean;
  role: string;
  setUser: (user: firebase.User | null) => void;
  logout: () => void;
};

export const AuthContext = React.createContext<ContextProps>(
  {} as ContextProps
);

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [role, setRole] = useState("");
  const [authenticated, setAuthenticated] = useState(false);
  const history = useHistory();

  const [loading, setLoading] = useState(true);

  const initialLoad = () => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user !== null) {
        const idTokenResult = await user.getIdTokenResult();
        const role = idTokenResult.claims.role;

        if (role !== "owner" && role !== "editor") {
          firebase.auth().signOut();
          window.location.href = "https://quickjobsjapan.com/";
          return;
        }
        setRole(role);
        setAuthenticated(true);
        setUser(user);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    initialLoad();
  }, []);

  if (loading) {
    return (
      <div className="spinner-wrapper">
        <Spin size="large" spinning={loading} />
      </div>
    );
  }

  const logout = async () => {
    setLoading(true);
    setAuthenticated(false);
    setRole("");
    setUser(null);
    setLoading(false);
    history.push("/");
    firebase.auth().signOut();
  };

  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        authenticated,
        setUser,
        logout,
        role,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
