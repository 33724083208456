import React from "react";
import "firebase/auth";
import "firebase/firestore";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ScrollToTop } from "./components";
import Login from "./pages/Login";
import "firebase/firestore";
import Jobs from "./pages/Jobs/Jobs";
import Companies from "./pages/Companies/List";
import EditCompany from "./pages/Companies/Edit";
import AddCompany from "./pages/Companies/Add";
import Articles from "./pages/Articles/List";
import EditArticle from "./pages/Articles/Edit";
import AddArticle from "./pages/Articles/Add";
import Admins from "./pages/Admins/List";
import EditAdmin from "./pages/Admins/Edit";
import AddAdmin from "./pages/Admins/Add";
import Users from "./pages/Users/List";
import UserDetail from "./pages/Users/Detail";
import { AuthProvider } from "./AuthProvider";
import PrivateRoute from "./PrivateRoute";
import Applicants from "pages/Applicants/List";
import UpdateUser from "pages/Companies/users/Update";
import StoreCreateUpdate from "pages/Companies/Store/CreateUpdate";
import CreateUpdate from "pages/Jobs/createUpdateJob";
import { DashBoardProvider } from "pages/DashboardLayout/DashboardContext";
import { Application } from "pages/Applicants/application";

function App() {
  return (
    <div>
      <CssBaseline />
      <Router>
        <ScrollToTop />
        <AuthProvider>
          <DashBoardProvider>
            <Switch>
              <Route exact path="/" component={Login} />
              <Switch>
                <PrivateRoute exact path="/jobs" component={Jobs} />
                <PrivateRoute exact path="/jobs/:id" component={CreateUpdate} />
                <PrivateRoute exact path="/admins" component={Admins} />
                <PrivateRoute exact path="/admins/add" component={AddAdmin} />
                <PrivateRoute exact path="/admins/:id" component={EditAdmin} />

                <PrivateRoute exact path="/companies" component={Companies} />
                <PrivateRoute
                  exact
                  path="/companies/add"
                  component={AddCompany}
                />
                <PrivateRoute
                  exact
                  path="/companies/:id"
                  component={EditCompany}
                />
                <PrivateRoute
                  exact
                  path="/companies/:companyId/stores/:id"
                  component={StoreCreateUpdate}
                />
                <PrivateRoute
                  exact
                  path="/companies/:companyId/users/:id"
                  component={UpdateUser}
                />

                <PrivateRoute exact path="/articles" component={Articles} />
                <PrivateRoute
                  exact
                  path="/articles/add"
                  component={AddArticle}
                />
                <PrivateRoute
                  exact
                  path="/articles/:id"
                  component={EditArticle}
                />

                <PrivateRoute exact path="/users" component={Users} />
                <PrivateRoute exact path="/users/:id" component={UserDetail} />

                <PrivateRoute exact path="/applicants" component={Applicants} />
                <PrivateRoute
                  exact
                  path="/applicants/:id"
                  component={Application}
                />
              </Switch>
            </Switch>
          </DashBoardProvider>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
