import React, { useEffect, useState } from "react";
import firebase from "../../firebase";
import Form from "./Form";
import { RouteComponentProps } from "react-router";
import DashboardLayout from "pages/DashboardLayout/Dashboard";

const db = firebase.firestore();
interface Props extends RouteComponentProps<{ id: string }> {}

function Edit(props: Props) {
  const { match } = props;

  const [company, setCompany] = useState({} as any);

  useEffect(() => {
    const getCompany = async () => {
      let query = db.collection("Articles").doc(match.params.id);
      const doc = await query.get();
      const data = doc.data()!;
      data.id = doc.id;
      setCompany(data);
    };

    getCompany();
  }, [match.params.id]);

  return (
    <DashboardLayout>
      {company.id && <Form companyData={company} />}
    </DashboardLayout>
  );
}

export default Edit;
