import React, { useContext, useEffect } from "react";
import { CompanyAddForm } from "components/Molecules/CompanyForm";
import { useHistory } from "react-router-dom";
import { DashBoardContext } from "pages/DashboardLayout/DashboardContext";
import BackIcon from "../../assets/icons/backIcon.svg";
import DashboardLayout from "pages/DashboardLayout/Dashboard";

function Add() {
  const router = useHistory();

  const { setCurrentTitle } = useContext(DashBoardContext);

  useEffect(() => {
    setCurrentTitle("addCompany");
  }, [setCurrentTitle]);

  return (
    <DashboardLayout>
      <div>
        <img
          src={BackIcon}
          alt="back"
          onClick={() => router.push("/companies")}
          style={{ height: "2em", cursor: "pointer" }}
        />
      </div>
      <CompanyAddForm />
    </DashboardLayout>
  );
}

export default Add;
