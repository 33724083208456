import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { MENU_ITEM_PLACEMENT } from "components";
import { Job } from "components/interfaces";
import { FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  BUSINESS_TYPE,
  EMPLOYMENT_TYPE,
  JobApplicationConfig,
} from "./helpers";

const { INPUT_VARIANT, TITLE_VARIANT } = JobApplicationConfig as any;

interface Props {
  formik: FormikProps<Job>;
  haveError: any;
}

const IndustryRegistration: React.FC<Props> = ({
  formik,
  haveError,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className="industry-registration-section">
      <Typography variant={TITLE_VARIANT}>{t("Employment Type")}</Typography>
      <FormControl variant={INPUT_VARIANT} required>
        <InputLabel>{t("Employment Type")}</InputLabel>
        <Select
          variant={INPUT_VARIANT}
          name="employmentType"
          MenuProps={MENU_ITEM_PLACEMENT}
          label={t("Employment Type")}
          value={formik.values.employmentType}
          error={haveError("employmentType").error}
          onChange={formik.handleChange}
        >
          {EMPLOYMENT_TYPE.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {t(item.label)}
            </MenuItem>
          ))}
        </Select>
        {haveError("employmentType").message && (
          <FormHelperText error>
            {haveError("employmentType").message}
          </FormHelperText>
        )}
      </FormControl>
      <FormControl variant={INPUT_VARIANT} required>
        <InputLabel>{t("Types of business")}</InputLabel>
        <Select
          variant={INPUT_VARIANT}
          name="businessType"
          MenuProps={MENU_ITEM_PLACEMENT}
          label={t("Types of business")}
          error={haveError("businessType").error}
          value={formik.values.businessType}
          onChange={formik.handleChange}
        >
          {BUSINESS_TYPE.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {t(item.label)}
            </MenuItem>
          ))}
        </Select>
        {haveError("businessType").message && (
          <FormHelperText error>
            {haveError("businessType").message}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

export { IndustryRegistration };
