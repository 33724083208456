export * from "./Molecules";
export * from "./Elements";

export const MENU_ITEM_PLACEMENT: any = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};
