import React, { useEffect, useState } from "react";
import { Button, Space, Table, Tag } from "antd";
import firebase from "../../firebase";
import moment from "moment";
import { createStyles, withStyles } from "@material-ui/styles";
import {
  Tooltip,
  WithStyles,
  Button as MButton,
  FormControl,
  Paper,
} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {
  buildDataFromDocs,
  buildResultsDocs,
  catchExceptionCallback,
} from "utils";
import { Link } from "react-router-dom";
import { DetailsOutlined } from "@material-ui/icons";
import {
  prefectureList,
  statusOfResidenceList,
  japaneseLevelList,
} from "../../utils";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import DashboardLayout from "pages/DashboardLayout/Dashboard";
import { APPLICATION_STATUS } from "./detail";

const styles = createStyles({
  root: {
    padding: "0 20",
  },
  block: {
    border: "2px solid #DEDBD5",
    borderRadius: "10px",
    margin: "25px 40px 25px 40px",
    padding: "30px",
    display: "flex",
    justifyContent: "space-between",
  },

  textField: {
    marginRight: 10,
  },
  searchForm: {
    display: "flex",
    alignItems: "baseline",
    gap: "10px",
  },
});

const db = firebase.firestore();

interface IApplicantsProps extends WithStyles<typeof styles> {}

const getStatusColor: any = {
  accepted: "green",
  rejected: "purple",
  pending: "yellow",
};

const Applicants: React.FC<IApplicantsProps> = (props) => {
  const { classes } = props;
  const [loading, setLoading] = useState(false);

  const [applicants, setApplicants] = useState<any>([]);
  const [companies, setCompanies] = useState<any>({});
  const [users, setUsers] = useState<any>({});
  const [jobs, setJobs] = useState<any>({});
  const [search, setSearch] = useState({
    residentStatus: "",
    japaneseLevel: "",
    prefecture: "",
  });

  const handleSearchChange = (e: any) => {
    e.persist();
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  const searchApplicants = async () => {
    try {
      setLoading(true);
      const { applicantsList, usersList, jobsList } = await getApplicants();
      const searchReady = applicantsList.map((applicant: any) => {
        return {
          ...applicant,
          japaneseLevel: jobsList[applicant.jobId]?.japaneseLevel,
          userJapaneseLevel: +usersList[applicant.uid]?.japaneseLevel?.replace(
            "N",
            ""
          ),
          jobResidentRequirement: jobsList[applicant.jobId]?.residentData,
          statusOfResidence1: usersList[applicant.uid]?.statusOfResidence1,
          statusOfResidence2: usersList[applicant.uid]?.statusOfResidence2,
          prefecture:
            jobsList[applicant.jobId]?.prefecture ??
            jobsList[applicant.jobId]?.address,
          userPrefecture: users[applicant.uid]?.prefecture,
        };
      });

      const searchParams: any = { ...search };
      if (search.japaneseLevel) {
        searchParams.japaneseLevelNumeric = +search.japaneseLevel.replace(
          "N",
          ""
        );
      }

      const filteredApplicants = _.chain(searchReady)
        .filter(
          (applicant) =>
            !searchParams.japaneseLevel ||
            (searchParams.japaneseLevel &&
              (applicant.japaneseLevel?.includes(
                searchParams.japaneseLevelNumeric
              ) ||
                (searchParams.japaneseLevelNumeric !== 0 &&
                  applicant.userJapaneseLevel !== 0 &&
                  applicant.userJapaneseLevel <=
                    searchParams.japaneseLevelNumeric) ||
                searchParams.japaneseLevelNumeric ===
                  applicant.userJapaneseLevel))
        )
        .filter(
          (applicant) =>
            !search.residentStatus ||
            (searchParams.residentStatus &&
              applicant.statusOfResidence1 &&
              applicant.statusOfResidence1 === searchParams.residentStatus) ||
            (applicant.statusOfResidence2 &&
              applicant.statusOfResidence2 === searchParams.residentStatus) ||
            (Array.isArray(applicant.jobResidentRequirement) &&
              applicant.jobResidentRequirement.includes(
                searchParams.residentStatus
              ))
        )
        .filter(
          (applicant) =>
            !search.prefecture ||
            (searchParams.prefecture &&
              (applicant.prefecture === searchParams.prefecture ||
                applicant.userPrefecture === searchParams.prefecture))
        )
        .value();

      setApplicants(filteredApplicants);
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setLoading(false);
    }
  };
  const { t } = useTranslation();

  const columns = [
    {
      title: t("Applied Date"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: any) => (
        <> {moment.unix(date.seconds).format("MMMM Do YYYY")}</>
      ),
    },
    {
      title: t("Name"),
      dataIndex: "uid",
      key: "uid",
      render: (id: string) => <>{users[id]?.name}</>,
    },
    {
      title: t("Job Title"),
      dataIndex: "jobId",
      key: "jobId",
      render: (id: string) => {
        return <>{jobs[id]?.title ?? jobs[id]?.titleEn}</>;
      },
    },

    {
      title: t("Comapany Name"),
      dataIndex: "companyId",
      key: "companyId",
      render: (id: string) => {
        return <>{companies[id]?.companyName}</>;
      },
    },
    {
      title: t("Company Id"),
      dataIndex: "companyId",
      key: "companyId",
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      render: (status: string) => {
        let item = APPLICATION_STATUS.find((item) => item.value === status);
        return (
          <Space size="middle">
            <Tag color={item?.color || "yellow"}>
              {item?.label || t("Pending")}
            </Tag>
          </Space>
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id: string) => (
        <Space>
          <Link to={`applicants/${id}`}>
            <Tooltip title="detail">
              <Button shape="default" icon={<DetailsOutlined />} />
            </Tooltip>
          </Link>
        </Space>
      ),
    },
  ];

  const getApplicants = async () => {
    const applicantsSnapshot = await db.collection("Applications").get();
    const applicantsList = buildResultsDocs(applicantsSnapshot);

    const promisesCompanies: any = [];
    const promisesUsers: any = [];
    const promisesJobs: any = [];
    applicantsList.map(
      (applicant: any) =>
        applicant.companyId &&
        promisesCompanies.push(
          db.collection("Companies").doc(applicant.companyId).get()
        )
    );
    applicantsList.map(
      (applicant: any) =>
        applicant.uid &&
        promisesUsers.push(db.collection("Users").doc(applicant.uid).get())
    );
    applicantsList.map(
      (applicant: any) =>
        applicant.jobId &&
        promisesJobs.push(db.collection("Jobs").doc(applicant.jobId).get())
    );

    const companiesShapshots = await Promise.all(promisesCompanies);
    const usersSnapshots = await Promise.all(promisesUsers);
    const jobsSnapshots = await Promise.all(promisesJobs);

    return {
      applicantsList,
      companiesList: buildDataFromDocs(companiesShapshots),
      usersList: buildDataFromDocs(usersSnapshots),
      jobsList: buildDataFromDocs(jobsSnapshots),
    };
  };

  useEffect(() => {
    const getApplicantsList = async () => {
      try {
        setLoading(true);
        const { applicantsList, companiesList, usersList, jobsList } =
          await getApplicants();
        setApplicants(applicantsList);
        setCompanies(companiesList);
        setUsers(usersList);
        setJobs(jobsList);
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setLoading(false);
      }
    };
    getApplicantsList();
  }, []);

  return (
    <DashboardLayout>
      <div className={classes.root}>
        <div className={classes.block}>
          <form
            className={classes.searchForm}
            onSubmit={(e) => {
              e.preventDefault();
              searchApplicants();
            }}
          >
            <FormControl variant="outlined" style={{ width: 220 }}>
              <InputLabel id="resident-status-search-label">
                {t("Resident Status")}
              </InputLabel>
              <Select
                labelId="resident-status-search-label"
                id="resident-status-search"
                name={"residentStatus"}
                value={search.residentStatus}
                label={t("Resident Status")}
                onChange={handleSearchChange}
              >
                <MenuItem value={""}>{t("Please Select")}</MenuItem>
                {statusOfResidenceList.map((status: string, index: any) => (
                  <MenuItem value={status} key={`status_${index}`}>
                    {t(status)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined" style={{ width: 180 }}>
              <InputLabel id="japanese-level-search-label">
                {t("Japanese Level")}
              </InputLabel>
              <Select
                labelId="japanese-level-search-label"
                id="japaneseLevel"
                name={"japaneseLevel"}
                value={search.japaneseLevel}
                label={t("Japanese Level")}
                onChange={handleSearchChange}
              >
                <MenuItem value={""}>{t("Please Select")}</MenuItem>
                {japaneseLevelList.map((level: string, index: any) => (
                  <MenuItem value={level} key={`level_${index}`}>
                    {t(level)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined" style={{ width: 220 }}>
              <InputLabel id="prefecture-search-label">
                {t("prefecture")}
              </InputLabel>
              <Select
                labelId="prefecture-search-label"
                name={"prefecture"}
                id="prefecture"
                value={search.prefecture}
                label="Prefecture"
                onChange={handleSearchChange}
              >
                <MenuItem value={""}>{t("Please Select")}</MenuItem>
                {prefectureList.map((prefecture: string, index: any) => (
                  <MenuItem value={prefecture} key={`prefecture_${index}`}>
                    {t(prefecture)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <MButton
              variant="contained"
              color="primary"
              disabled={loading ? true : false}
              type="submit"
            >
              探す
            </MButton>
          </form>
        </div>
        <Paper>
          <Table dataSource={applicants} columns={columns} loading={loading} />
        </Paper>
      </div>
    </DashboardLayout>
  );
};

export default withStyles(styles)(Applicants);
