import React, { useEffect, useState } from "react";

type ContextProps = {
  currentNavbar: string;
  setCurrentNavbar: (currentNavbar: string) => void;
  routes: any;
  currentTitle: string;
  setCurrentTitle: (currentTitle: string) => void;
};

export const DashBoardContext = React.createContext<ContextProps>(
  {} as ContextProps
);

export const DashBoardProvider = ({ children }: any) => {
  const [currentNavbar, setCurrentNavbar] = useState("求人");
  const [currentTitle, setCurrentTitle] = useState("");

  const routes: any = {
    求人: "求人一覧",
    企業: "企業一覧",
    記事一覧: "記事一覧",
    ユーザー: "ユーザーのリスト",
    管理者: "管理者のリスト",
    応募者: "応募者一覧",
    jobAdd: "ジョブを追加",
    addCompany: "企業を追加",
    addArticle: "記事を追加",
    addAdmin: "管理者を追加",
    editJob: "仕事の詳細",
    editCompany: "企業を更新",
    applicantDetail: "応募者詳細",
    updateUser: "担当者設定",
    createStore: "店舗追加",
    updateStore: "店舗を更新",
  };

  useEffect(() => {
    setCurrentTitle(currentNavbar);
  }, [currentNavbar]);

  return (
    <DashBoardContext.Provider
      value={{
        currentNavbar,
        setCurrentNavbar,
        routes,
        currentTitle,
        setCurrentTitle,
      }}
    >
      {children}
    </DashBoardContext.Provider>
  );
};
