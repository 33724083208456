import React from "react";
import { Chip } from "@material-ui/core";

const InputChip = (props: any) => {
  const { data } = props;
  if (data) {
    return (
      <>
        {data.map((item: any) => (
          <Chip
            color="primary"
            style={{ marginRight: "20px" }}
            label={item}
            key={item}
          />
        ))}
      </>
    );
  }
  return <></>;
};

export default InputChip;
