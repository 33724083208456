import React, { useContext, useEffect, useState } from "react";
import { Theme } from "@material-ui/core/styles/createTheme";
import { StyleRules } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { WithStyles } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import green from "@material-ui/core/colors/green";
import firebase from "../../firebase";
import "firebase/auth";
import "firebase/firestore";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../AuthProvider";
import Brand from "./../../assets/images/brand.png";
import { useTranslation } from "react-i18next";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    wrapper: {
      padding: 18,
    },
    formError: {
      color: theme.palette.error.main,
    },
    buttonWrapper: {
      position: "relative",
      display: "inline-block",
    },
    gridContainer: {
      marginTop: 20,
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
    },
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    formHelperText: {
      marginBottom: 15,
    },
    text: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "22px",
      lineHeight: "24px",
      letterSpacing: "0.44px",
    },
    placeholder: {
      fontSize: "12px",
      linHeight: "16px",
      textAlign: "left",
      color: theme.palette.error.main,
    },
    brandImage: {
      width: "40%",
      marginBottom: "2em",
    },
  });

interface Props extends WithStyles<typeof styles> {}

interface FormItems {
  email: string;
  password: string;
}

interface FormErrors extends FormItems {
  form: string;
}

function SignIn(props: Props) {
  const { classes } = props;

  const { authenticated } = useContext(AuthContext);

  const { t } = useTranslation();

  const history = useHistory();

  const [values, setValues] = useState({
    email: "",
    password: "",
  } as FormItems);
  const [errors, setErrors] = useState({} as FormErrors);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (authenticated) {
      history.push("/jobs");
    }
  }, [authenticated, history]);

  const loadingDone = () => {
    setLoading(false);
    window.scrollTo(0, 0);
  };

  const handleChange = (event: any) => {
    event.persist();
    setErrors({ ...errors, form: "" });
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleErrors = (newErrors: { [key: string]: string }) => {
    setErrors((errors) => ({
      ...errors,
      form: t("Email or password is incorrect"),
    }));
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(values.email, values.password)
      .then(() => {
        loadingDone();
      })
      .catch((error) => {
        handleErrors({ form: `${error.message} (${error.code})` });
        loadingDone();
      });
  };

  return (
    <React.Fragment>
      <form noValidate className={classes.wrapper} onSubmit={handleSubmit}>
        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item xs={12} sm={3}>
            <img src={Brand} className={classes.brandImage} alt="brand" />
            {errors.form ? (
              <Typography
                variant="body1"
                gutterBottom
                className={classes.formError}
              >
                {errors.form}
              </Typography>
            ) : (
              ""
            )}
            <TextField
              id="email"
              name="email"
              label={t("Email")}
              type="email"
              placeholder=""
              helperText=""
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.email}
              onChange={handleChange}
              error={!!errors.email}
            />
            {values.email === "" ? (
              <Typography
                variant="body1"
                gutterBottom
                className={classes.placeholder}
              >
                {t("Enter your Email")}
              </Typography>
            ) : null}
            <TextField
              id="password"
              name="password"
              type="password"
              label={t("Password")}
              placeholder=""
              helperText=""
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.password}
              onChange={handleChange}
              error={!!errors.password}
            />

            <FormControl margin="normal">
              <div className={classes.buttonWrapper}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={loading}
                >
                  ログイン
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}

export default withStyles(styles)(SignIn) as React.FC;
