import { createStyles, WithStyles } from "@material-ui/core";
import { t } from "i18next";
import * as yup from "yup";

export const TEXT_FIELD_VARIANT = "outlined";
export const INITIAL_DATA = {
  companyName: "",
  companyNameEn: "",
  address: "",
  websiteURL: "",
  email: "",
  name: "",
  phone: "",
  password: "",
  confirmPassword: "",
};
export const EDIT_INITIAL_DATA = {
  ...INITIAL_DATA,
  personInCharge: [],
};

export const styles = createStyles({
  wrapper: {
    marginTop: 8,
  },
  actionWrapper: {
    marginTop: 8,
  },
  editWrapper: {
    marginBottom: 72,
  },
  selectError: {
    borderColor: "red",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const commonValidationSchema = {
  companyName: yup.string().required(t("Company Name is required")),
  address: yup.string().required(t("Address is Required")),
  websiteURL: yup
    .string()
    .matches(
      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
      t("Enter valid url")
    )
    .required(t("Website URL is required")),
};
export const validationSchema = yup.object().shape({
  ...commonValidationSchema,
  email: yup
    .string()
    .email(t("Enter valid email"))
    .required(t("Email is Required")),
  password: yup.string().required(t("Password is required")),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref("password")],
      t("Password and Confirm Password do not match")
    )
    .required(t("Confirm Password is required")),
  name: yup.string().required(t("Person In Charge is Required")),
  phone: yup
    .number()
    .required(t("Phone number is required"))
    .typeError(t("Enter valid phone number")),
});

export const updateValidationSchema = yup.object().shape({
  ...commonValidationSchema,
  personInCharge: yup
    .array()
    .min(1, t("Person In Charge is Required"))
    .required(t("Person In Charge is Required")),
});

export interface Props extends WithStyles<typeof styles> {}
