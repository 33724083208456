import React, { useState } from "react";
import {
  createStyles,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

export const styles = createStyles({
  errorMessage: {
    paddingLeft: 12,
    color: "red",
  },
});

interface Props extends WithStyles<typeof styles> {
  id: string;
  value: string;
  onChange: any;
  error?: boolean;
  fullWidth?: boolean;
  helperText?: string | boolean;
  InputLabelProps?: unknown;
}

const _PasswordInput: React.FC<Props> = (props: Props) => {
  const { id, classes, error, helperText, ...rest } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = handleClickShowPassword;

  return (
    <>
      <OutlinedInput
        id={props.id}
        type={showPassword ? "text" : "password"}
        error={error}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        {...rest}
      />
      <FormHelperText className={classes.errorMessage}>
        {error && helperText}
      </FormHelperText>
    </>
  );
};

export const PasswordInput = withStyles(styles)(_PasswordInput);
