import React, { useEffect, useState } from "react";
import firebase from "../../firebase";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Link from "@material-ui/core/Link";
import createStyles from "@material-ui/core/styles/createStyles";
import { WithStyles } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Space, Spin } from "antd";
import DashboardLayout from "pages/DashboardLayout/Dashboard";
import { useTranslation } from "react-i18next";
import moment from "moment";

const styles = createStyles({
  block: {
    border: "2px solid #DEDBD5",
    borderRadius: "10px",
    margin: "25px 40px 50px 40px",
    padding: "30px",
    display: "flex",
    justifyContent: "space-between",
  },
  prevNextBtn: {
    marginTop: 20,
    textAlign: "center",
    "& :first-child": {
      marginRight: "5px",
    },
    "& :last-child": {
      marginLeft: "5px",
    },
  },
  add: {
    padding: "25px 80px 20px 20px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#F8F5EF",
    borderRadius: "5px",
  },
  addButton: {
    padding: "10px 20px 10px 20px",
    backgroundColor: "#39404F",
    borderRadius: "40px",
    border: "1px solid #39404F",
    cursor: "pointer",
    fontSize: "13px",
    color: "#FFFFFF",
    lineHeight: "20px",
    fontWeight: 500,
  },
  text: {
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: 500,
  },
  textField: {
    marginRight: 10,
  },
});

const db = firebase.firestore();

interface Props extends WithStyles<typeof styles> {}

let users: any = [];

function List(props: Props) {
  const { classes } = props;
  const [userList, setUserList] = useState([] as any[]);
  const [isLoading, setLoading] = useState({ prev: false, next: false });
  const [loader, setLoader] = useState(false);
  const { t } = useTranslation();

  const [pagination, setPagination] = useState({
    hasPrevious: false,
    hasNext: true,
    pageSize: 10,
    page: -1,
    lastVisible: {},
  });

  const [search, setSearch] = useState({
    name: "",
    email: "",
  });

  useEffect(() => {
    next();
    // eslint-disable-next-line
  }, []);

  let newPagination = pagination;
  const next = async () => {
    setLoading({ ...isLoading, next: true });
    if (users[newPagination.page + 2] === undefined) {
      let query;
      if (Object.keys(newPagination.lastVisible).length !== 0) {
        query = db
          .collection("Users")
          .orderBy("createdAt", "desc")
          .startAfter(pagination.lastVisible)
          .limit(pagination.pageSize);
      } else {
        query = db
          .collection("Users")
          .orderBy("createdAt", "desc")
          .limit(pagination.pageSize);
      }
      const snapShot = await query.get();
      if (snapShot.docs.length) {
        const list = await Promise.all(
          snapShot.docs.map(async (doc) => {
            const data = doc.data();
            data.id = doc.id;
            return data;
          })
        );
        if (list.length) {
          users.push(list);
        }
        newPagination = {
          ...newPagination,
          page: newPagination.page + 1,
          lastVisible: snapShot.docs[snapShot.docs.length - 1],
          hasPrevious:
            newPagination.page === -1 ? false : newPagination.page >= 0,
        };
        setPagination({ ...newPagination });
      } else {
        newPagination = {
          ...newPagination,
          page: newPagination.page + 1,
          hasPrevious: newPagination.page >= 0,
          hasNext: false,
        };
        setPagination({ ...newPagination });
      }
    } else {
      newPagination = {
        ...newPagination,
        page: newPagination.page + 1,
        hasPrevious: newPagination.page >= 0,
        hasNext: true,
      };
      setPagination(newPagination);
    }

    setLoading({ ...isLoading, next: false });
    setUserList(users[newPagination?.page] || []);
  };

  const previous = () => {
    setLoading((prevState) => ({ ...prevState, prev: true }));
    newPagination = {
      ...newPagination,
      page: newPagination.page - 1,
      hasPrevious: pagination.page - 1 > 0,
      hasNext: true,
    };
    setPagination({ ...newPagination });
    setLoading((prevState) => ({ ...prevState, prev: false }));
    setUserList(users[newPagination.page]);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setSearch((search: any) => ({
      ...search,
      [event.target.name]: event.target.value,
    }));
  };

  const searchDb = async () => {
    setLoader(true);
    let userRef = db.collection("Users").limit(9);
    if (search.name) {
      userRef = userRef.where("name", "==", search.name);
    }
    if (search.email) {
      userRef = userRef.where("email", "==", search.email);
    }

    const snapShot = await userRef.get();
    const userList: any[] = [];
    snapShot.docs.forEach((doc) => {
      const data = doc.data();
      data.id = doc.id;
      userList.push(data);
    });
    setUserList(userList);
    setLoader(false);
  };

  const getJaDate = (jsDate: any) => {
    if (moment(jsDate).isValid())
      return moment(jsDate).format("YYYY年MM月DD日").toString();
    return "--";
  };

  const UI = () => {
    if (userList) {
      return userList.map((user) => (
        <TableRow key={user.id}>
          <TableCell>
            <Link href={`/users/${user.id}`}>{user.name}</Link>
          </TableCell>
          <TableCell>{user.email}</TableCell>
          <TableCell>{user.visa ? user.visa : user.nationality}</TableCell>
          <TableCell>{user.japaneseLevel}</TableCell>
          <TableCell>{user.prefecture}</TableCell>
          <TableCell>{user.applied ? user.applied : 0}</TableCell>
          <TableCell>{user.saved ? user.saved : 0}</TableCell>
          <TableCell>{user.viewed ? user.viewed : 0}</TableCell>
          <TableCell>
            {user.lastVisitedDate ? getJaDate(user.lastVisitedDate) : "N/A"}
          </TableCell>
          <TableCell>{getJaDate(user.createdAt.toDate())}</TableCell>
        </TableRow>
      ));
    } else
      return (
        <TableRow>
          <TableCell>No User found</TableCell>
        </TableRow>
      );
  };

  return (
    <DashboardLayout>
      <Spin spinning={isLoading.next}>
        <div>
          <div className={classes.block}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                searchDb();
              }}
            >
              <Space>
                <TextField
                  id="name"
                  name="name"
                  placeholder="名前"
                  size={"small"}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.textField}
                  value={search.name}
                  onChange={handleSearchChange}
                />
                <TextField
                  id="email"
                  name="email"
                  placeholder="Eメール"
                  size={"small"}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.textField}
                  value={search.email}
                  onChange={handleSearchChange}
                />
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loader ? true : false}
                  type="submit"
                >
                  探す
                </Button>
              </Space>
            </form>
          </div>

          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>名前</TableCell>
                  <TableCell>メールアドレス</TableCell>
                  <TableCell>在留資格</TableCell>
                  <TableCell>日本語</TableCell>
                  <TableCell>県</TableCell>
                  <TableCell>応募</TableCell>
                  <TableCell>保存</TableCell>
                  <TableCell>閲覧</TableCell>
                  <TableCell>最終アクセス日</TableCell>
                  <TableCell>登録日</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{UI()}</TableBody>
            </Table>
          </Paper>
          <div className={classes.prevNextBtn}>
            <Button
              color="primary"
              variant="contained"
              disabled={!pagination.hasPrevious || isLoading?.prev}
              onClick={previous}
            >
              <NavigateBeforeIcon />
              {t("Prev")}
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={!pagination.hasNext || isLoading?.next}
              onClick={next}
            >
              {t("Next")}
              <NavigateNextIcon />
            </Button>
          </div>
        </div>
      </Spin>
    </DashboardLayout>
  );
}

export default withStyles(styles)(List);
