import React from "react";
import { Chip, Grid, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface TagProps {
  list: Array<{ value: string; label: string }>;
  selected: string[];
  label: string;
  onChange: (e: string[]) => void;
}
const useStyles = makeStyles({
  chip: {
    marginLeft: "0.5rem",
    marginBottom: "0.5rem",
  },
  label: {
    fontSize: "0.75rem",
  },
});

const TagGroup: React.FC<TagProps> = ({
  list,
  onChange,
  selected,
  label,
}: TagProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const handleChange = (value: string) => {
    let temp = selected;
    if (selected.includes(value)) temp = temp.filter((item) => item !== value);
    else temp.push(value);
    onChange(temp);
  };

  return (
    <div>
      <Typography className={classes.label}>{label}</Typography>
      <Grid container>
        {list.map((item: { label: string; value: string }) => (
          <Chip
            key={item.value}
            label={t(item.label)}
            className={classes.chip}
            onClick={() => handleChange(item.value)}
            color={
              selected.filter((ite) => ite === item.value).length > 0
                ? "primary"
                : undefined
            }
            clickable
          />
        ))}
      </Grid>
    </div>
  );
};

export { TagGroup };
