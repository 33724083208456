import React, { useEffect, useState } from "react";
import firebase from "../../firebase";
import { RouteComponentProps, withRouter } from "react-router";
import createStyles from "@material-ui/core/styles/createStyles";
import { WithStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import DashboardLayout from "pages/DashboardLayout/Dashboard";

const styles = createStyles({
  root: {
    padding: 20,
  },
  block: {
    marginBottom: 20,
  },
});

const db = firebase.firestore();

interface Props
  extends WithStyles<typeof styles>,
    RouteComponentProps<{ id: string }> {}

function Detail(props: Props) {
  const { classes, match } = props;

  const [userDetail, setUserDetail] = useState({} as any);

  useEffect(() => {
    const getUserDetail = async () => {
      let query = db.collection("Users").doc(match.params.id);
      const doc = await query.get();
      const data = doc.data()!;
      setUserDetail(data);
    };

    getUserDetail();
  }, [match.params.id]);

  return (
    <DashboardLayout>
      <div className={classes.root}>
        <div className={classes.block}>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={12}>
              <strong>Name:</strong> {userDetail.name ? userDetail.name : "N/A"}
            </Grid>
            <Grid item xs={12} sm={12}>
              <strong>Email:</strong>{" "}
              {userDetail.email ? userDetail.email : "N/A"}
            </Grid>
            <Grid item xs={12} sm={12}>
              <strong>Gender:</strong>{" "}
              {userDetail.gender ? userDetail.gender : "N/A"}
            </Grid>
            <Grid item xs={12} sm={12}>
              <strong>Telephone:</strong>{" "}
              {userDetail.tel ? userDetail.tel : "N/A"}
            </Grid>
            <Grid item xs={12} sm={12}>
              <strong>Birthday:</strong>{" "}
              {userDetail.birthday ? userDetail.birthday : "N/A"}
            </Grid>
            <Grid item xs={12} sm={12}>
              <strong>Nationality:</strong>{" "}
              {userDetail.nationality ? userDetail.nationality : "N/A"}
            </Grid>
            <Grid item xs={12} sm={12}>
              <strong>Current Work:</strong>{" "}
              {userDetail.currentWork ? userDetail.currentWork : "N/A"}
            </Grid>
            <Grid item xs={12} sm={12}>
              <strong>In Japan:</strong>{" "}
              {userDetail.inJapan ? userDetail.inJapan : "N/A"}
            </Grid>
            <Grid item xs={12} sm={12}>
              <strong>Japanese Level:</strong>{" "}
              {userDetail.japaneseLevel ? userDetail.japaneseLevel : "N/A"}
            </Grid>
            <Grid item xs={12} sm={12}>
              <strong>Language:</strong>{" "}
              {userDetail.language ? userDetail.language : "N/A"}
            </Grid>
            <Grid item xs={12} sm={12}>
              <strong>Non Qualified Activity:</strong>{" "}
              {userDetail.nonQualifiedActivity
                ? userDetail.nonQualifiedActivity
                : "N/A"}
            </Grid>
            <Grid item xs={12} sm={12}>
              <strong>Non Qualified Period:</strong>{" "}
              {userDetail.nonQualifiedPeriod
                ? userDetail.nonQualifiedPeriod
                : "N/A"}
            </Grid>
            <Grid item xs={12} sm={12}>
              <strong>Status of Residence:</strong>{" "}
              {userDetail.statusOfResidence
                ? userDetail.statusOfResidence
                : "N/A"}
            </Grid>
            <Grid item xs={12} sm={12}>
              <strong>Wish Full Time:</strong>{" "}
              {userDetail.wishFullTime ? userDetail.wishFullTime : "N/A"}
            </Grid>
          </Grid>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default withStyles(styles)<any>(withRouter(Detail)) as any;
