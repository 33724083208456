/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { catchExceptionCallback } from "../../utils";
import { JobForm } from "components";
import firebase from "firebase";
import { Spin } from "antd";
import DashboardLayout from "pages/DashboardLayout/Dashboard";

const getUrlParams = (hash: string) => {
  return hash.slice(hash.indexOf("#") + 1);
};

interface ICreateUpdateProps extends RouteComponentProps<{ id: string }> {}
const db = firebase.firestore();
const CreateUpdate: React.FC<ICreateUpdateProps> = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const cloneId = getUrlParams(props.location.hash);
  const [job, setJob] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const getJob = async (jobId: string) => {
    try {
      const snap = await db.collection("Jobs").doc(jobId).get();
      let exist = snap.exists;
      if (!exist) return;
      const jobData: any = await snap.data();
      jobData.id = id;
      setJob(cloneId ? { draft: jobData?.draft } : jobData);
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    cloneId ? getJob(cloneId) : id && getJob(id);
  }, [id]);

  const fetchJob = () => getJob(id);
  return (
    <DashboardLayout>
      <Spin spinning={loading}>
        <JobForm
          job={job}
          jobId={id}
          isNew={Boolean(cloneId || !job)}
          refetchJob={fetchJob}
        />
      </Spin>
    </DashboardLayout>
  );
};

export default CreateUpdate;
