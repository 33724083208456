import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./AuthProvider";

const PrivateRoute = ({ component: RouteComponent, ...rest }: any) => {
  const { loading, authenticated } = useContext(AuthContext);

  if (loading) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={routeProps => {
        if (!authenticated) {
          return (
            <Redirect to={{ pathname: "/", state: { prevPath: rest.path } }} />
          );
        }

        return <RouteComponent {...routeProps} />;
      }}
    />
  );
};

export default PrivateRoute;
