import firebase from "firebase/app";
import "firebase/storage/";
import firebaseConfig from "./config/firebase";

firebase.initializeApp(firebaseConfig);

// TODO: For development purpose only
if (process.env.REACT_APP_EMULATOR === "true") {
  firebase.firestore().settings({
    host: "localhost:8080",
    ssl: false,
  });
}

export default firebase;
