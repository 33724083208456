import {
  Button,
  Dialog,
  makeStyles,
  TextField,
  IconButton,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState } from "react";
import ModalInvite from "./ModalInvite";
import { catchExceptionCallback } from "utils";
import { inviteCompanyUser } from "services";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

interface Props {
  companyId: string;
  modalStatus: boolean;
  handleModalClose: any;
  setUserReload: any;
  setPersonInchargeReload: any;
}

const defaultValues = {
  name: "",
  email: "",
  role: "company/editor",
} as any;

const useStyles = makeStyles((theme) => ({
  root: {
    width: 600,
    height: 400,
    marginTop: "4%",
    paddingLeft: "10%",
  },
  heading: {
    textAlign: "center",
  },
  label: {
    fontWeight: 500,
    paddingLeft: "4%",
  },
  nameInput: {
    width: "90%",
    verticalAlign: "middle",
  },
  emailInput: {
    paddingLeft: "20%",
    verticalAlign: "middle",
  },
  inputLayout: {
    marginTop: "30px",
  },
  btnStyle: {
    marginLeft: "80%",
    backgroundColor: "#3f51b5",
    color: "white",
    "&:hover": {
      backgroundColor: "#3f51b5",
    },
  },
  closeBtn: {
    top: 0,
    right: 0,
    position: "absolute",
  },
  loaderContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 99999,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.2)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  circularProgress: {
    color: "#E16D30",
  },
}));

const ModalForm: React.FC<Props> = (props) => {
  const {
    modalStatus,
    handleModalClose,
    companyId,
    setUserReload,
    setPersonInchargeReload,
  } = props;
  const [inviteModalStatus, setInviteModalStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(defaultValues);
  const classes = useStyles();
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t("Enter valid email"))
      .required(t("Email is Required")),
    name: yup.string().required(t("Name is Required")),
    role: yup.string().required(t("Role is required")),
  });

  const formik = useFormik({
    initialValues: { ...initialValues, companyId },
    validationSchema: validationSchema,
    onSubmit: (data: unknown) => handleSubmit(data),
  });

  const handleInviteModalClose = () => setInviteModalStatus(false);

  const dispatchInviteModal = () => {
    handleModalClose();
    setInviteModalStatus(true);
    setInitialValues(defaultValues);
  };

  const handleSubmit = async (data: any) => {
    try {
      setIsLoading(true);
      await inviteCompanyUser(data);
      dispatchInviteModal();
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setIsLoading(false);
      setUserReload(true);
      setPersonInchargeReload(true);
    }
  };

  return (
    <React.Fragment>
      <Dialog open={modalStatus} onClose={handleModalClose}>
        <div className={classes.root}>
          <IconButton onClick={handleModalClose} className={classes.closeBtn}>
            <CloseIcon />
          </IconButton>
          <form onSubmit={formik.handleSubmit}>
            <h1 className={classes.heading}>{t("Invite Person-In-Charge")}</h1>
            <Grid item xs={12}>
              <InputLabel>{t("Name")}</InputLabel>
              <TextField
                id="name"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                className={classes.nameInput}
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                onChange={formik.handleChange}
              />
            </Grid>
            <br />
            <Grid item xs={12} md={12}>
              <InputLabel>{t("Email")}</InputLabel>
              <TextField
                id="email"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                className={classes.nameInput}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <br />
            <Grid item xs={12}>
              <InputLabel>{t("Role")}</InputLabel>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                value={formik.values.role}
                name="role"
                id="role"
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="company/admin"
                  control={<Radio />}
                  label={t("Adminstrator")}
                />
                <FormControlLabel
                  value="company/editor"
                  control={<Radio />}
                  label={t("General")}
                />
              </RadioGroup>
            </Grid>

            <Button
              variant="contained"
              type="submit"
              className={classes.btnStyle}
              disabled={isLoading}
            >
              {t("Invite")}
            </Button>
          </form>
        </div>
      </Dialog>
      {isLoading && (
        <div className={classes.loaderContainer}>
          <CircularProgress className={classes.circularProgress} size={100} />
        </div>
      )}
      {!modalStatus && inviteModalStatus && (
        <ModalInvite
          success={true}
          modalStatus={inviteModalStatus}
          handleModalClose={handleInviteModalClose}
        />
      )}
    </React.Fragment>
  );
};

export default ModalForm;
