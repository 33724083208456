import React from "react";
import {
  Button as MUIButton,
  createStyles,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { Button } from "./Button";
import { ArrowLeftOutlined } from "@material-ui/icons";

const styles = createStyles({
  searchWrapper: {
    border: "2px solid #DEDBD5",
    borderRadius: "10px",
    margin: "25px 40px 25px 40px",
    padding: "30px",
    display: "flex",
  },
  add: {
    padding: "25px 80px 20px 20px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#F8F5EF",
    borderRadius: "5px",
  },
  text: {
    fontSize: "20px",
    margin: "auto",
    paddingLeft: 18,
    fontWeight: 500,
  },
  leftAction: {
    display: "flex",
    alignItems: "center",
  },
  searchLabel: {
    display: "flex",
    marginTop: "5px",
    marginRight: "15px",
    alignItems: "center",
    color: "#000000",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 500,
  },
});

interface Props extends WithStyles<typeof styles> {
  title: string;
  addPath?: string;
  goBack?: () => void;
}

const _CommonHeader: React.FC<Props> = ({
  title,
  addPath,
  classes,
  goBack,
}: Props) => {
  return (
    <div className={classes.add}>
      <div className={classes.leftAction}>
        {goBack && (
          <MUIButton variant="outlined" size="small" onClick={goBack}>
            <ArrowLeftOutlined />
          </MUIButton>
        )}
        <div>
          <p className={classes.text}>{title}</p>
        </div>
      </div>
      {addPath && (
        <Button button_type="button" path={addPath} text="追加"></Button>
      )}
    </div>
  );
};

export const CommonHeader = withStyles(styles)(_CommonHeader);
