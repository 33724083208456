import React, { useState } from "react";
import {
  Grid,
  InputLabel,
  TextField,
  Button,
  withStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  INITIAL_DATA,
  styles,
  TEXT_FIELD_VARIANT,
  validationSchema,
  Props,
} from "./helper";
import { useFormik } from "formik";
import { PasswordInput, showError } from "components";
import { createCompany } from "services";
import { useHistory } from "react-router-dom";

export const CompanyInformationCommonForm: React.FC<{ formik: any }> = ({
  formik,
}: {
  formik: any;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid item xs={12}>
        <InputLabel>{t("Comapany Name")}</InputLabel>
        <TextField
          id="companyName"
          fullWidth
          variant={TEXT_FIELD_VARIANT}
          value={formik.values.companyName}
          error={
            formik.touched.companyName && Boolean(formik.errors.companyName)
          }
          helperText={formik.touched.companyName && formik.errors.companyName}
          onChange={formik.handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <InputLabel>{t("Company Name (English)")}</InputLabel>
        <TextField
          id="companyNameEn"
          fullWidth
          variant={TEXT_FIELD_VARIANT}
          value={formik.values.companyNameEn}
          error={
            formik.touched.companyNameEn && Boolean(formik.errors.companyNameEn)
          }
          helperText={
            formik.touched.companyNameEn && formik.errors.companyNameEn
          }
          onChange={formik.handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <InputLabel>{t("Address")}</InputLabel>
        <TextField
          id="address"
          fullWidth
          variant={TEXT_FIELD_VARIANT}
          value={formik.values.address}
          error={formik.touched.address && Boolean(formik.errors.address)}
          helperText={formik.touched.address && formik.errors.address}
          onChange={formik.handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <InputLabel>{t("Website URL")}</InputLabel>
        <TextField
          id="websiteURL"
          fullWidth
          variant={TEXT_FIELD_VARIANT}
          value={formik.values.websiteURL}
          onChange={formik.handleChange}
          error={formik.touched.websiteURL && Boolean(formik.errors.websiteURL)}
          helperText={formik.touched.websiteURL && formik.errors.websiteURL}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </>
  );
};

const AddForm: React.FC<Props> = ({ classes }: Props) => {
  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);
  const router = useHistory();
  const formik = useFormik({
    initialValues: INITIAL_DATA,
    validationSchema: validationSchema,
    onSubmit: (data: unknown) => handleSubmit(data),
  });

  const handleSubmit = (_data: any) => {
    let data = { ..._data };
    setSubmitting(true);
    delete data.confirmPassword;
    createCompany(data)
      .then((res) => router.push(`/companies/${res.data.id}`))
      .catch((err) => {
        showError(err?.response?.data?.message || err?.message);
        setSubmitting(false);
      });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid item xs={7}>
        <Grid container spacing={2} className={classes.wrapper}>
          <CompanyInformationCommonForm formik={formik} />
          <Grid item xs={12}>
            <InputLabel>{t("Person In Charge")}</InputLabel>
            <TextField
              id="name"
              name="name"
              fullWidth
              variant={TEXT_FIELD_VARIANT}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>{t("Phone Number")}</InputLabel>
            <TextField
              id="phone"
              name="phone"
              fullWidth
              variant={TEXT_FIELD_VARIANT}
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>{t("Email")}</InputLabel>
            <TextField
              id="email"
              fullWidth
              variant={TEXT_FIELD_VARIANT}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>{t("Password")}</InputLabel>
            <PasswordInput
              id="password"
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>{t("Confirm Password")}</InputLabel>
            <PasswordInput
              id="confirmPassword"
              fullWidth
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
            />
          </Grid>
          <Grid
            container
            justifyContent="center"
            className={classes.actionWrapper}
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
            >
              {t("Register Account")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export const CompanyAddForm = withStyles(styles)(AddForm);
