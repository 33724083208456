import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import { api } from "../../config/firebase";
import axios from "axios";
import { axiosConfig } from "../../auth";
import createStyles from "@material-ui/core/styles/createStyles";
import { Table, withStyles } from "@material-ui/core";
import { EditOutlined, DeleteOutlined } from "@material-ui/icons";
import { Space, Button as ActionButton, Spin, Modal, message } from "antd";
import { useTranslation } from "react-i18next";
import { catchExceptionCallback } from "utils";
import { DashBoardContext } from "../../pages/DashboardLayout/DashboardContext";
import DashboardLayout from "pages/DashboardLayout/Dashboard";
import { deleteUser } from "services";

interface Admin {
  id: string;
  name: string;
  email: string;
  phone: string;
}

const styles = createStyles({
  add: {
    padding: "25px 80px 20px 20px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row-reverse",
  },
  text: {
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: 500,
  },
  addButton: {
    padding: "10px 20px 10px 20px",
    backgroundColor: "#39404F",
    borderRadius: "5px",
    border: "1px solid #39404F",
    cursor: "pointer",
    fontSize: "13px",
    color: "#FFFFFF",
    lineHeight: "20px",
    fontWeight: 500,
  },
});

function List(props: any) {
  const { classes } = props;

  const history = useHistory();

  const { setCurrentTitle } = useContext(DashBoardContext);

  const [adminList, setAdminList] = useState<Admin[]>([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCurrentTitle("管理者");
    getAdminList();
  }, [setCurrentTitle]);

  const getAdminList = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${api.host}/admin/account/list`,
        null,
        await axiosConfig()
      );
      setAdminList(response.data);
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    Modal.confirm({
      title: t("Are you sure delete this admin?"),
      okText: t("Confirm"),
      cancelText: t("Cancel"),
      onOk: async () => {
        try {
          setLoading(true);
          await deleteUser(id);
          setAdminList((user) => user.filter((admin) => admin.id !== id));
          message.success(t("Admin Successfully Deleted"));
        } catch (error) {
          catchExceptionCallback(error);
        } finally {
          setLoading(false);
        }
      },
    });
  };

  return (
    <DashboardLayout>
      <div>
        <div className={classes.add}>
          <div>
            <button
              onClick={() => history.push("/admins/add")}
              className={classes.addButton}
            >
              追加
            </button>
          </div>
        </div>
        <Spin spinning={loading}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>{t("Phone Number")}</TableCell>
                <TableCell>担当者名</TableCell>
                <TableCell>{t("Email")}</TableCell>
                <TableCell>{t("Action")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {adminList.map((admin) => (
                <TableRow key={admin.id}>
                  <TableCell>{admin.id}</TableCell>
                  <TableCell>{admin.phone}</TableCell>
                  <TableCell>
                    <Link component={RouterLink} to={`/admins/${admin.id}`}>
                      {admin.name}
                    </Link>
                  </TableCell>
                  <TableCell>{admin.email}</TableCell>
                  <TableCell>
                    <Space>
                      <ActionButton
                        onClick={() => history.push(`/admins/${admin.id}`)}
                        icon={<EditOutlined />}
                      />
                      <ActionButton
                        onClick={() => handleDelete(admin.id)}
                        disabled={loading}
                        icon={<DeleteOutlined />}
                        danger
                      />
                    </Space>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Spin>
      </div>
    </DashboardLayout>
  );
}

export default withStyles(styles)(List);
