import React, { useEffect, useState } from "react";
import firebase from "firebase";
import { Row, Space, Table, Button, Popconfirm } from "antd";
import {
  Button as MaterialButton,
  Typography,
  makeStyles,
  Paper,
} from "@material-ui/core";
import ModalForm from "./ModalForm";
import { useHistory } from "react-router-dom";
import {
  buildResultsDocs,
  catchExceptionCallback,
  successNotification,
  USER_ROLES_COMPANY,
} from "utils";
import { EditOutlined, Lock, Delete } from "@material-ui/icons";
import { deleteUser, getCompanyLoginToken } from "services";
import { company_site } from "config/firebase";
import { useTranslation } from "react-i18next";

const db = firebase.firestore();

interface ITableInfo {
  id: string;
  displayName: string;
  email: string;
  phone: string;
  isActive: boolean;
}

interface IUsersProps {
  companyId: string;
  setPersonInchargeReload: any;
}

const styles = makeStyles((theme) => ({
  deactiveBackground: {
    backgroundColor: "red",
  },
  activeBackground: {
    backgroundColor: "#4CAF50",
  },
}));

const List: React.FC<IUsersProps> = ({
  companyId,
  setPersonInchargeReload,
}) => {
  const [modalStatus, setModalStatus] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const history = useHistory();
  const classes = styles();
  const [loading, setLoading] = useState(true);
  const [userReload, setUserReload] = useState(false);

  const handleModalOpen = () => setModalStatus(true);
  const handleModalClose = () => setModalStatus(false);

  const { t } = useTranslation();

  const handleCompanyLogin = async (uid: string) => {
    try {
      setLoading(true);
      const { token } = await getCompanyLoginToken(uid);
      window.open(`${company_site.host}?token=${token}`, "_blank");
    } catch (e) {
      catchExceptionCallback(e);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (uid: string) => {
    try {
      setLoading(true);
      await deleteUser(uid);
      const newUserList = users.filter((u: any) => u.id !== uid);
      setUsers(newUserList);
      successNotification("User removed successfully");
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setLoading(false);
      setPersonInchargeReload(true);
    }
  };

  useEffect(() => {
    const getUsers = async () => {
      try {
        setLoading(true);
        const snapshot = await db
          .collection("Admins")
          .where("companyId", "==", companyId)
          .get();
        setUsers(buildResultsDocs(snapshot));
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setLoading(false);
      }
    };

    getUsers();
  }, [companyId, userReload]);

  const columns = (onDelete: any) => [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Phone Number"),
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: t("Email Address"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("Role"),
      dataIndex: "role",
      key: "role",
      render: (role: string) => {
        return (
          <>
            {
              Object.values(USER_ROLES_COMPANY).find((i) => i.value === role)
                ?.label
            }
          </>
        );
      },
    },
    {
      title: t("Account Status"),
      dataIndex: "isActive",
      key: "isActive",
      render: (value: any, person: ITableInfo) => {
        return (
          <button
            className={
              person.isActive
                ? classes.activeBackground
                : classes.deactiveBackground
            }
            style={{
              border: "none",
              color: "white",
              padding: "8px 20px",
              width: "135px",
              textAlign: "center",
              textDecoration: "none",
              display: "inline-block",
              fontSize: "16px",
              borderRadius: "40px",
            }}
          >
            {person.isActive ? t("Active") : t("Inactive")}
          </button>
        );
      },
    },
    {
      title: " ",
      dataIndex: "id",
      key: "id",
      render: (id: string, data: any) => {
        return (
          <Space>
            <Button
              icon={<EditOutlined />}
              onClick={() =>
                history.push(`/companies/${companyId}/users/${id}`)
              }
            />
            <Button
              icon={<Lock />}
              onClick={() => {
                handleCompanyLogin(id);
              }}
            ></Button>
            <Popconfirm
              placement="topRight"
              title={t("Are you sure you want to delete this User ?")}
              okText={t("Yes")}
              cancelText={t("No")}
              onConfirm={() => onDelete(id)}
              arrowPointAtCenter
            >
              <Button
                hidden={data.role === USER_ROLES_COMPANY.owner.value}
                icon={<Delete />}
              ></Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  return (
    <>
      <div
        style={{
          marginTop: "72px",
        }}
      >
        <Row justify="space-between">
          <Typography variant="h5">{t("User Management")}</Typography>
          <div style={{ marginBottom: "20px" }}>
            <MaterialButton
              variant="contained"
              color="primary"
              onClick={handleModalOpen}
            >
              {t("Add User")}
            </MaterialButton>
          </div>
        </Row>
        <Paper>
          <Table
            dataSource={users}
            columns={columns(handleDelete)}
            rowKey="id"
            size="small"
            loading={loading}
          />
        </Paper>
      </div>
      <ModalForm
        companyId={companyId}
        modalStatus={modalStatus}
        handleModalClose={handleModalClose}
        setUserReload={setUserReload}
        setPersonInchargeReload={setPersonInchargeReload}
      />
    </>
  );
};

export default List;
