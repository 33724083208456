/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Space, Spin, Typography, Select, Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { catchExceptionCallback, successNotification } from "../../utils";
import firebase from "firebase";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { t as _t } from "i18next";
import BackIcon from "./../../assets/icons/backIcon.svg";
import { DashBoardContext } from "pages/DashboardLayout/DashboardContext";
import { BUSINESS_TYPE } from "components/Molecules/Job/helpers";

const { Title, Paragraph } = Typography;

const Wrapper = styled.div`
  padding: 0 20px;
`;

const MarginCol = styled(Col)`
  margin: 1rem auto;
`;
const FlexSpace = styled(Space)`
  display: flex;
`;

const ApplicationForm = styled.form`
  margin-top: 10px;
  .select-box {
    width: 150px;
  }
`;

type ICustomProps = {
  title: string;
  value: string;
};

type IDetailProps = {
  id: string;
};

export const APPLICATION_STATUS = [
  {
    label: _t("Unsupported"),
    value: "unsupported",
    color: "red",
  },
  {
    label: _t("Already Responded (by e-mail)"),
    value: "already-responded-by-email",
    color: "yellow",
  },
  {
    label: _t("Already Responded (by phone call)"),
    value: "already-responded-by-email",
    color: "yellow",
  },
  {
    label: _t("Interview adjustment completed"),
    value: "interview-adjustment-completed",
    color: "yellow",
  },
  {
    label: _t("Passed 1st interview"),
    value: "passed-first-interview",
    color: "green",
  },
  {
    label: _t("Passed 2nd interview"),
    value: "passed-second-interview",
    color: "green",
  },
  {
    label: _t("Passed 3rd interview"),
    value: "passed-third-interview",
    color: "green",
  },
  {
    label: _t("Informal offer/accepted"),
    value: "informal-offer-accepted",
    color: "green",
  },
  { label: _t("Rejected status"), value: "rejected", color: "red" },
  { label: _t("No contact/no reply"), value: "no-contact", color: "red" },
];

const Detail: React.FC<IDetailProps> = (props: IDetailProps) => {
  const { id } = props;
  const history = useHistory();
  const { setCurrentTitle } = useContext(DashBoardContext);
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState<any>(null);
  const [job, setJob] = useState<any>(null);
  const [applicant, setApplicant] = useState<any>(null);
  const [submitting, setSubmitting] = useState<any>(false);
  const { t } = useTranslation();

  useEffect(() => {
    setCurrentTitle("applicantDetail");
    const getApplications = async () => {
      try {
        setLoading(true);
        const applicationSnapshot = await firebase
          .firestore()
          .collection("Applications")
          .doc(id)
          .get();
        const application: any = applicationSnapshot.data();
        const jobSnapshot = await firebase
          .firestore()
          .collection("Jobs")
          .doc(application.jobId)
          .get();
        const applicantSnapshot = await firebase
          .firestore()
          .collection("Users")
          .doc(application.uid)
          .get();
        setApplication(application);
        setJob(jobSnapshot.data());
        setApplicant(applicantSnapshot.data());
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setLoading(false);
      }
    };

    getApplications();
  }, [id]);

  const formik = useFormik({
    initialValues: {
      status: application?.status || "pending",
      memo: application?.memo || "",
    },
    enableReinitialize: true,
    onSubmit: (data: unknown) => handleSubmit(data),
  });

  const handleSubmit = async (_data: any) => {
    try {
      setSubmitting(true);
      let data = { ..._data };
      await firebase
        .firestore()
        .collection("Applications")
        .doc(id)
        .update(data);
      successNotification(t("Application Updated Successfully"));
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setSubmitting(false);
    }
  };

  const CustomRow = (props: ICustomProps) => {
    return (
      <Row gutter={[48, 0]}>
        <Col span={8}>{props.title}</Col>
        <Col>
          <Paragraph italic={true}>{props.value}</Paragraph>
        </Col>
      </Row>
    );
  };

  return (
    <Spin spinning={loading || submitting}>
      <Wrapper>
        <div>
          <img
            src={BackIcon}
            alt="back"
            onClick={() => history.push("/applicants")}
            style={{ height: "2em", marginBottom: "2em", cursor: "pointer" }}
          />
        </div>
        {application && (
          <Row>
            <MarginCol span={24} offset={3}>
              <FlexSpace direction="vertical" size="middle">
                <CustomRow title={t("Application Id")} value={id} />
                <CustomRow
                  title={t("Applied Date")}
                  value={application.createdAt.toDate().toString()}
                />
              </FlexSpace>
              {job && (
                <>
                  <MarginCol>
                    <Title level={4}>{t("Job Information")}</Title>
                  </MarginCol>
                  <FlexSpace direction="vertical" size="middle">
                    <CustomRow
                      title={t("Comapany Name")}
                      value={job.companyName}
                    />
                    <CustomRow title={t("Job Id")} value={application.jobId} />
                    <CustomRow title={t("Job Title")} value={job.title} />
                    <Row gutter={[48, 0]}>
                      <Col span={8}>{t("Job Description")}</Col>
                      <Col span={16}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: t(job.jobDescription),
                          }}
                        />
                      </Col>
                    </Row>
                    <CustomRow
                      title={t("Business Type")}
                      value={t(
                        BUSINESS_TYPE.find(
                          (item) => item.value === job.businessType
                        )?.label || "-"
                      )}
                    />
                    <CustomRow
                      title={t("Transportation allowance")}
                      value={t(job.travelExpenses)}
                    />
                  </FlexSpace>
                </>
              )}
              {applicant && (
                <>
                  <MarginCol>
                    <Title level={4}>{t("Applicant Information")}</Title>
                  </MarginCol>
                  <FlexSpace direction="vertical" size="middle">
                    <CustomRow title={t("Name")} value={applicant.name} />
                    <CustomRow
                      title={t("Email Address")}
                      value={applicant.email}
                    />
                  </FlexSpace>
                </>
              )}

              <ApplicationForm onSubmit={formik.handleSubmit}>
                <FlexSpace direction="vertical" size="middle">
                  <Row gutter={[48, 0]}>
                    <Col span={8}>{t("Status")}</Col>
                    <Col>
                      <Select
                        className="select-box"
                        value={formik.values.status}
                        onChange={(value) =>
                          formik.setFieldValue("status", value)
                        }
                      >
                        {APPLICATION_STATUS.map((item) => (
                          <Select.Option value={item.value} key={item.value}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                  <Row gutter={[48, 0]}>
                    <Col span={8}>{t("Memo")}</Col>
                    <Col>
                      <TextArea
                        value={formik.values.memo}
                        name="memo"
                        id="memo"
                        onChange={formik.handleChange}
                        rows={2}
                        cols={30}
                      />
                    </Col>
                  </Row>
                </FlexSpace>
                <FlexSpace>
                  <Button type="primary" htmlType="submit" loading={submitting}>
                    {t("Update")}
                  </Button>
                </FlexSpace>
              </ApplicationForm>
            </MarginCol>
          </Row>
        )}
      </Wrapper>
    </Spin>
  );
};
export default Detail;
