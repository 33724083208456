/* eslint-disable react-hooks/exhaustive-deps */
import {
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { Button, Spin } from "antd";
import { useFormik } from "formik";
import DashboardLayout from "pages/DashboardLayout/Dashboard";
import { DashBoardContext } from "pages/DashboardLayout/DashboardContext";
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { getUser, updateUserDetail } from "services";
import { catchExceptionCallback, successNotification } from "utils";
import * as yup from "yup";
import BackIcon from "../../../assets/icons/backIcon.svg";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: "center",
  },
  label: {
    fontWeight: 500,
    paddingLeft: "4%",
  },
  input: {
    width: 850,
  },
  checkBox: {
    marginTop: "20px",
  },
  gridLayout: {
    marginTop: "30px",
    verticalAlign: "middle",
  },
  updateBtn: {
    marginLeft: "30%",
    marginTop: "15px",
    backgroundColor: "#645A82",
    border: "none",
    color: "white",
    padding: "15px 32px",
    width: "140px",
    height: "50px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "16px",
    borderRadius: "20px",
  },
}));

interface IProps
  extends RouteComponentProps<{ id: string; companyId: string }> {}

const Update: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    match: {
      params: { id, companyId },
    },
  } = props;
  const { setCurrentTitle } = useContext(DashBoardContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [user, setUser] = useState<any>(null);
  const { t } = useTranslation();

  useEffect(() => {
    setCurrentTitle("updateUser");
    const getCompanyUser = async () => {
      try {
        setIsSubmitting(true);
        const user = await getUser(id);
        setUser(user);
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setIsSubmitting(false);
      }
    };
    id && getCompanyUser();
  }, [id]);

  const validationSchema = yup.object().shape({
    name: yup.string().required(t("Name is Required")),
    phone: yup.string().required(t("Phone number is required")),
    email: yup.string().required(t("Email is Required")),
    changePassword: yup.boolean().required(),
    newPassword: yup.string().when("changePassword", {
      is: true,
      then: yup.string().required(t("Please enter new password")),
    }),
    confirmPassword: yup.string().when("changePassword", {
      is: true,
      then: yup
        .string()
        .oneOf(
          [yup.ref("newPassword"), null],
          t("Password and Confirm Password do not match")
        )
        .required(t("Please enter confirm password")),
    }),
  });

  const handleSubmit = async (values: any) => {
    try {
      setIsSubmitting(true);
      let payload = { ...values };
      delete payload.id;
      await updateUserDetail(id, payload);
      successNotification(t("Data updated successfully"));
      history.push(`/companies/${companyId}`);
    } catch (error: any) {
      catchExceptionCallback(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      role: "company/editor",
      changePassword: false,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      ...user,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data: unknown) => handleSubmit(data),
  });

  return (
    <DashboardLayout>
      <Spin spinning={isSubmitting}>
        <div>
          <img
            src={BackIcon}
            alt="back"
            style={{ height: "2em", marginBottom: "2em", cursor: "pointer" }}
            onClick={() => history.push(`/companies/${companyId}`)}
          />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <h1 className={classes.heading}>{t("Person In Charge")}</h1>

          <Grid container spacing={0} className={classes.gridLayout}>
            <Grid item style={{ width: 300 }}>
              <label className={classes.label}>{t("Name")}:</label>
            </Grid>
            <Grid item>
              <TextField
                className={classes.input}
                required
                variant="outlined"
                name="name"
                id="name"
                InputLabelProps={{ shrink: true }}
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
          </Grid>

          <Grid container spacing={0} className={classes.gridLayout}>
            <Grid item style={{ width: 300 }}>
              <label className={classes.label}>{t("Phone Number")}:</label>
            </Grid>
            <Grid item>
              <TextField
                className={classes.input}
                required
                variant="outlined"
                name="phone"
                id="phone"
                InputLabelProps={{ shrink: true }}
                value={formik.values.phone}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
          </Grid>

          <Grid container spacing={0} className={classes.gridLayout}>
            <Grid item style={{ width: 300 }}>
              <label className={classes.label}>{t("Email Address")}:</label>
            </Grid>
            <Grid item>
              <TextField
                className={classes.input}
                required
                variant="outlined"
                name="email"
                id="email"
                InputLabelProps={{ shrink: true }}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0} className={classes.gridLayout}>
            <Grid item style={{ width: 300 }}>
              <label className={classes.label}>{t("Role")}:</label>
            </Grid>
            <Grid item>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                value={formik.values.role}
                name="role"
                id="role"
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="company/editor"
                  control={<Radio />}
                  label={t("Public")}
                />
                <FormControlLabel
                  value="company/admin"
                  control={<Radio />}
                  label={t("Adminstrator")}
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <div className={classes.checkBox}>
            <Checkbox
              id="changePassword"
              name="changePassword"
              color="primary"
              checked={formik.values.changePassword}
              onChange={formik.handleChange}
            />
            <label>{t("Change Password")}</label>
          </div>

          {formik.values.changePassword && (
            <>
              <Grid container spacing={0} className={classes.gridLayout}>
                <Grid item style={{ width: 300 }}>
                  <label className={classes.label}>{t("New Password")}:</label>
                </Grid>
                <Grid item>
                  <TextField
                    className={classes.input}
                    variant="outlined"
                    name="newPassword"
                    id="newPassword"
                    type={"password"}
                    value={formik.values.newPassword}
                    error={
                      formik.touched.newPassword &&
                      Boolean(formik.errors.newPassword)
                    }
                    helperText={
                      formik.touched.newPassword && formik.errors.newPassword
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={0} className={classes.gridLayout}>
                <Grid item style={{ width: 300 }}>
                  <label className={classes.label}>
                    {t("Confirm Password")}:
                  </label>
                </Grid>
                <Grid item>
                  <TextField
                    className={classes.input}
                    variant="outlined"
                    name="confirmPassword"
                    id="confirmPassword"
                    type={"password"}
                    value={formik.values.confirmPassword}
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
            </>
          )}
          <Button
            type="primary"
            htmlType="submit"
            size={"large"}
            className={classes.updateBtn}
            disabled={isSubmitting}
          >
            {t("Update")}
          </Button>
        </form>
      </Spin>
    </DashboardLayout>
  );
};

export default Update;
