import React, { useState } from "react";
import { Button, Typography } from "@material-ui/core";
import { Space } from "antd";
import { useTranslation } from "react-i18next";
import { JobApplicationConfig } from "./helpers";
import { JobListingBody } from "./JobListingBody";
import { Job } from "components/interfaces";
import { FormikProps } from "formik";

const { TITLE_VARIANT } = JobApplicationConfig as any;

type mode = "japanese" | "english";
interface Props {
  formik: FormikProps<Job>;
  haveError: any;
}
const JobListingAdvertisement: React.FC<Props> = ({
  formik,
  haveError,
}: Props) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState<mode>("japanese");
  return (
    <div className="job-ad-listing-sections">
      <div className="header">
        <Typography variant={TITLE_VARIANT}>
          {t("Job listing advertisement creation")}
        </Typography>
        <Space>
          <Button
            variant={mode === "english" ? "contained" : undefined}
            color="primary"
            onClick={() => setMode("english")}
          >
            {t("English")}
          </Button>
          <Button
            variant={mode === "japanese" ? "contained" : undefined}
            color="primary"
            onClick={() => setMode("japanese")}
          >
            {t("Japanese")}
          </Button>
        </Space>
      </div>
      <JobListingBody mode={mode} formik={formik} haveError={haveError} />
    </div>
  );
};

export { JobListingAdvertisement };
