import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import firebase from "firebase";
import { useFormik } from "formik";
import {
  Button,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  withStyles,
} from "@material-ui/core";
import { CompanyInformationCommonForm } from "./AddForm";
import { Company, User } from "components/interfaces";
import { showError } from "components/Elements";
import {
  EDIT_INITIAL_DATA,
  Props,
  styles,
  MenuProps,
  updateValidationSchema,
} from "./helper";
import { successNotification } from "utils";

interface EditProps extends Props {
  id: string;
  data?: Company;
  loading: boolean;
  personIncharges: User[];
}
const db = firebase.firestore();
const _EditForm: React.FC<EditProps> = ({
  id,
  data,
  classes,
  loading,
  personIncharges,
}: EditProps) => {
  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);

  const formik = useFormik<Company>({
    initialValues: data ?? EDIT_INITIAL_DATA,
    enableReinitialize: true,
    validationSchema: updateValidationSchema,
    onSubmit: (_data: object) => {
      const data = { ..._data } as Company;
      handleSubmit(data);
    },
  });

  const handleSubmit = async (data: Company) => {
    setSubmitting(true);
    try {
      await db.collection("Companies").doc(id).set(data);
      successNotification(t("Data updated successfully"));
    } catch (err: any) {
      showError(err?.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className={classes.editWrapper}>
      <form onSubmit={formik.handleSubmit}>
        <Grid item xs={7}>
          <Spin spinning={loading}>
            <Grid container spacing={2} className={classes.wrapper}>
              <CompanyInformationCommonForm formik={formik} />
              <Grid item xs={12}>
                <InputLabel>{t("Person In Charge")}</InputLabel>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.personInCharge &&
                      formik.errors.personInCharge
                  )}
                >
                  <Select
                    id="personInCharge"
                    multiple
                    value={
                      personIncharges.length > 0
                        ? formik.values?.personInCharge || []
                        : []
                    }
                    className={
                      Boolean(
                        formik.touched.personInCharge &&
                          formik.errors.personInCharge
                      )
                        ? classes.selectError
                        : undefined
                    }
                    fullWidth
                    variant="outlined"
                    onChange={(e) =>
                      formik.setFieldValue("personInCharge", e.target.value)
                    }
                    renderValue={(selected: any) => (
                      <div className={classes.chips}>
                        {selected.map((id: string) => (
                          <Chip
                            key={id}
                            label={
                              personIncharges.find((value) => value.id === id)
                                ?.name
                            }
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {personIncharges.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {formik.touched.personInCharge &&
                      formik.errors.personInCharge}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid
                container
                justifyContent="center"
                className={classes.actionWrapper}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {t("Update")}
                </Button>
              </Grid>
            </Grid>
          </Spin>
        </Grid>
      </form>
    </div>
  );
};

export const EditForm = withStyles(styles)(_EditForm);
