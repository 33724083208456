import React, { useState } from "react";
import {
  getFieldErrorNames,
  INITIAL_VALUES,
  styles,
  ValidationSchema,
} from "./helpers";
import { Job } from "components/interfaces";
import { Button, Card, Grid, withStyles, WithStyles } from "@material-ui/core";
import { PersonInCharge } from "./PersonInCharge";
import { IndustryRegistration } from "./IndustryRegistration";
import { JobListingAdvertisement } from "./JobListingAdvertisement";
import { ApplicationRequirements } from "./ApplicationRequirements";
import { useFormik } from "formik";
import {
  catchExceptionCallback,
  generateDraftUrl,
  successNotification,
} from "utils";
import firebase from "firebase";
import { Space } from "antd";
import { showSuccess } from "components/Elements";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

interface JobFormProps extends WithStyles<typeof styles> {
  job: Job;
  isNew: boolean;
  jobId: string;
  refetchJob: () => void;
}

const scrollBehavior: any = { behavior: "smooth", block: "center" };
const db = firebase.firestore();
const _JobForm: React.FC<JobFormProps> = ({
  job,
  classes,
  isNew,
  jobId,
  refetchJob,
}: JobFormProps) => {
  const [loading, setLoading] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const router = useHistory();
  const [upLoading, setUpLoading] = useState(false);

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: job?.draft
      ? { ...INITIAL_VALUES, ...job.draft }
      : INITIAL_VALUES,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: ValidationSchema,
    onSubmit: (data) => {
      handleSubmit(data);
    },
  });

  const gotoDraftView = () => {
    window.open(generateDraftUrl(jobId, 1), "_blank", "noopener,noreferrer");
  };

  const handleScrollOnError = async () => {
    let errors: any = await formik.validateForm();
    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length <= 0) return;
    let element: any = undefined;
    for (let i = 0; i < fieldErrorNames.length; i++) {
      if (fieldErrorNames[i].split(".").length > 0)
        element = document.getElementById(fieldErrorNames[i].split(".")[0]);
      if (!element)
        element = document.querySelector(`input[name='${fieldErrorNames[i]}']`);
      if (!element) element = document.getElementById(fieldErrorNames[i]);
      if (element) break;
    }
    if (!element) return;
    element.scrollIntoView(scrollBehavior);
  };

  const handleSubmit = async (values: Job, mode = "published") => {
    const _setLoading = (val: boolean) =>
      mode === "published" ? setLoading(val) : setDraftLoading(val);
    try {
      _setLoading(true);
      const now = firebase.firestore.Timestamp.now();
      const timestamp = { updatedAt: now } as any;
      if (isNew) timestamp.createdAt = now;
      const draftData: any = {
        ...values,
        ...timestamp,
        companyId: values?.companyId,
        companyName: values?.companyName,
      };

      let params = {
        draft: draftData,
        ...timestamp,
        isPublished: mode !== "save-draft",
        companyId: values?.companyId,
        companyName: values?.companyName,
      } as any;

      if (mode === "published") {
        params = {
          ...params,
          ...draftData,
          hasPublished: true,
          isPublic: "yes",
        };
      }
      await db.collection("Jobs").doc(jobId).set(params, { merge: true });
      showSuccess(
        mode === "published"
          ? t("Job published successfully")
          : t("Job draft saved successfully")
      );
      refetchJob();
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      isNew && router.push(`/jobs/${jobId}`);
      _setLoading(false);
    }
  };

  const handlePublish = async () => {
    await handleScrollOnError();
    formik.handleSubmit();
  };

  const handleUnpublish = async () => {
    try {
      setUpLoading(true);
      await db
        .collection("Jobs")
        .doc(jobId)
        .update({ isPublic: "no", isPublished: true });
      refetchJob();
      successNotification(t("Job unpublished successfully"));
    } catch (err) {
      catchExceptionCallback(err);
    } finally {
      isNew && router.push(`/jobs/${jobId}`);
      setUpLoading(false);
    }
  };

  interface forArray {
    error: boolean;
    message: string;
  }
  interface ReturnType {
    error: boolean;
    message: string;
    forArray: (index: number, subKey: string) => any;
  }

  const haveError = (keyName: string): ReturnType => {
    let error: boolean, message: any;
    error = Boolean(formik.errors[keyName] && formik.touched[keyName])
      ? true
      : false;
    message = error ? formik.errors[keyName] : "";
    const forArray = (index: number, subKey: string) => {
      let error = false;
      let message = "";
      let errorObj: any = formik.errors[keyName] as any;
      let touchedObj: any = formik.touched[keyName] as any;
      let touched = Boolean(
        Array.isArray(touchedObj) &&
          touchedObj[index] &&
          touchedObj[index][subKey]
      );
      if (touched) {
        error = Boolean(
          Array.isArray(errorObj) &&
            errorObj[index] &&
            errorObj[index][subKey] &&
            touched
        );
      }
      if (error) message = errorObj[index][subKey];
      return {
        error,
        message,
      };
    };
    let errorObj: ReturnType = {
      error,
      message,
      forArray,
    };

    return errorObj;
  };

  const showDraft = () => Boolean(job?.hasPublished && job?.isPublic === "yes");

  return (
    <div className={classes.fromWrapper}>
      <Grid item md={12} lg={4}>
        <PersonInCharge formik={formik} haveError={haveError} />
      </Grid>
      <Grid item md={12} lg={4}>
        <IndustryRegistration formik={formik} haveError={haveError} />
      </Grid>
      <Grid item md={12} lg={10}>
        <ApplicationRequirements formik={formik} haveError={haveError} />
      </Grid>
      <Grid item md={12} lg={10}>
        <JobListingAdvertisement formik={formik} haveError={haveError} />
      </Grid>
      {/* Footer */}
      <Grid item xs={10}>
        <Card className="footer">
          <div>
            {!isNew && (
              <Button
                onClick={() => {
                  gotoDraftView();
                }}
                color="primary"
              >
                {t("Preview")}
              </Button>
            )}
          </div>

          <Space>
            {showDraft() && (
              <Button
                variant="contained"
                className={`${upLoading ? "" : "danger"}`}
                disabled={upLoading}
                onClick={handleUnpublish}
              >
                {t("Unpublish")}
              </Button>
            )}
            <Button
              variant="contained"
              disabled={draftLoading}
              onClick={() => handleSubmit(formik.values, "save-draft")}
            >
              {t("Save draft")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={handlePublish}
            >
              {t("Publish")}
            </Button>
          </Space>
        </Card>
      </Grid>
    </div>
  );
};

export const JobForm = withStyles(styles)(_JobForm);
