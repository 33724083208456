const H1 = {
  fontWeight: 700,
  fontSize: "28px",
  lineHeight: "41px",
  letterSpacing: "1.4px",
  fontFamily: "'Noto Sans JP',sans-serif",
};
const Heading = {
  fontWeight: "bold",
  fontSize: "22px",
  lineHeight: "26px",
  fontFamily: "'Noto Sans JP',sans-serif",
};

const BigHeading = {
  fontWeight: 400,
  fontFamily: "'Noto Sans JP',sans-serif",
  fontSize: "24px",
  lineHeight: "36px",
};

const H2 = {
  fontFamily: "'Noto Sans JP',sans-serif",
  fontSize: "32px",
  lineHeight: "47px",
  letterSpacing: "0px",
  fontWeight: 400,
};
const HeadingAlt = {
  fontWeight: 700,
  fontSize: "32px",
  lineHeight: "32px",
  letterSpacing: "1.6px",
  fontFamily: "'Noto Sans JP',sans-serif",
};

const PackTitle = {
  fontWeight: 500,
  // lineHeight: "50px",
  fontSize: "16px",
  fontFamily: "'Noto Sans JP',sans-serif",
};

const Pack = {
  fontWeight: 700,
  fontSize: "16px",
  fontFamily: "'Noto Sans JP',sans-serif",
};

const PackDesc = {
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "22px",
  fontFamily: "'Noto Sans JP',sans-serif",
};

const PetButton = {
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "21px",
  fontFamily: "'Noto Sans JP',sans-serif",
};

const List = {
  fontWeight: "bold",
  fontSize: "18px",
  lineHeight: "27px",
  letterSpacing: "0.9px",
  fontFamily: "'Noto Sans JP',sans-serif",
};
const Normal = {
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "60px",
  fontFamily: "'Noto Sans JP',sans-serif",
};

const Desc = {
  fontSize: "14px",
  fontWeight: 400,
  fontFamily: "'Noto Sans JP',sans-serif",
};

const Stuff = {
  fontWeight: 700,
  fontFamily: "'Noto Sans JP',sans-serif",
  lineHeight: "30px",
  fontSize: "20px",
};

const Tag = {
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "21px",
  fontFamily: "'Noto Sans JP',sans-serif",
};

const smallTitle = {
  fontSize: "13px",
  fontWeight: 500,
  lineHeight: "20px",
  fontFamily: "'Noto Sans JP',sans-serif",
};

export const theme = {
  black: "#000000",
  base: "#FFFFFF",
  yellow: "#FFF100",
  yellow1: "#FFFF00",
  gray9: "#262626",
  gray8: "#595959",
  borderColorBase: "#d9d9d9",
  primary: "#EB6014",
  primaryDark: "#a84e1c",
  textBlack: "#333",
  primaryAlt: "#5FB2DA",
  bgLight: "#FDFDFE",
  lightBlack: "#887659",
  primaryText: "#313131",
  radio: "#f4860b",
  lightText: "#999999",
  lightGrey1: "#F2F1EE",
  primaryLight: "#FBE2D5",
  grey3: "#DBDEE3",
  bgDisabled: "#f5f5f5",
  bgGrey: "#E5E5E5",
  green: "#67A868",
  lightgreen: "#51A048",
  black1: "#525252",
  secondary: "#F8A227",
  line: "#1ABC04",
  link: "#1589CF",
  grey: "#D2D2D2",
  lightPrimary: "#FBE9DF",
  orange: "#EB6014",
  lightGrey: "#F4F6FA",
  textSecondary: "#39251A",
  breadcrumb: "#535353",
  grey2: "#bfbfbf",
  border: "#D2D2D2",
  borderBtn: "#959595",
  selectArrow: "#313131",
  textPrimary: "#313131",
  alert: "#EB5757",
  capsule: "#898989",
  dark1: "#1A1A1A",
  accent: "#80722A",
  light: "#AAAAAA",
  grey1: "#DDD",
  darkGrey: "#999999",
  adminPrimary: "#65B798",
  size: "1010px",
  sizePlusOne: "1011px",
  lightBlue: "#B9CBF2",
  red: "#ff4d4f",
  grey5f: "#5F5F5F",
  darkYellow: "#FBB03A",
  white: "#FFFFFF",
  skyBlue: "#60BEB6",
  darkGreen: "#51A048",
  green2: "#439A4F",
  greyBC: "#BCBCBC",
  yellowMid: "#EA9C00",
  greyF0: "#F0F0F0",
  lightPink: "rgba(244, 180, 0, 0.12)",
  black4: "#444444",
  yellowDark2: "#CE9F1A",
  grey78: "#787878",

  typography: {
    H1,
    Normal,
    Heading,
    HeadingAlt,
    List,
    BigHeading,
    H2,
    Tag,
    Pack,
    PackTitle,
    Stuff,
    PackDesc,
    PetButton,
    Desc,
    smallTitle,
  },
};
