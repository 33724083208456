import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { EditForm } from "components";
import firebase from "firebase";
import { Company, User } from "components/interfaces";
import UserList from "./users/List";

import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { StoreList } from "./Store/List";
import BackIcon from "../../assets/icons/backIcon.svg";
import { DashBoardContext } from "pages/DashboardLayout/DashboardContext";
import DashboardLayout from "pages/DashboardLayout/Dashboard";
import { buildResultsDocs, catchExceptionCallback } from "utils";

const db = firebase.firestore();
const styles = createStyles({
  bodyContainer: {
    marginRight: 32,
  },
});

interface Props
  extends RouteComponentProps<{ id: string }>,
    WithStyles<typeof styles> {}

const EditCompany: React.FC<Props> = ({ match, classes }: Props) => {
  const { setCurrentTitle } = useContext(DashBoardContext);
  const [loading, setLoading] = useState(true);
  const [personInchargeReload, setPersonInchargeReload] = useState(false);
  const [company, setCompany] = useState<Company>();
  const [personIncharges, setPersonIncharges] = useState<User[]>([]);
  const router = useHistory();
  const id = match.params.id;

  useEffect(() => {
    setCurrentTitle("editCompany");
    const getCompany = async () => {
      try {
        setLoading(true);
        const companiesSnap = await db.collection("Companies").doc(id).get();
        setCompany({ ...companiesSnap.data(), id } as Company);
        const companyUserRef = await db
          .collection("Admins")
          .where("companyId", "==", id)
          .get();
        const personInCharge = buildResultsDocs(companyUserRef) as User[];
        setPersonIncharges(personInCharge || []);
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setLoading(false);
      }
    };
    getCompany();
  }, [id, personInchargeReload, setCurrentTitle]);

  return (
    <DashboardLayout>
      <div>
        <img
          src={BackIcon}
          alt="back"
          onClick={() => router.push("/companies")}
          style={{ height: "2em", marginBottom: "2em", cursor: "pointer" }}
        />
      </div>
      <EditForm
        data={company}
        loading={loading}
        id={id}
        personIncharges={personIncharges}
      />
      <div className={classes.bodyContainer}>
        <UserList
          companyId={id}
          setPersonInchargeReload={setPersonInchargeReload}
        />
        <StoreList companyId={id} />
      </div>
    </DashboardLayout>
  );
};

export default withStyles(styles)(EditCompany);
