import React, { useContext, useEffect, useState } from "react";
import { InputLabel, TextField } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import MaterialButton from "@material-ui/core/Button";
import firebase from "../../../firebase";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import {
  catchExceptionCallback,
  prefectureList,
  successNotification,
} from "utils";
import { SelectComponent as Select } from "components";
import * as yup from "yup";
import BackIcon from "../../../assets/icons/backIcon.svg";
import { DashBoardContext } from "pages/DashboardLayout/DashboardContext";
import { Spin } from "antd";

const styles = createStyles({
  root: {
    padding: "0 20",
  },
  block: {
    marginBottom: 20,
  },
  add: {
    padding: "25px 80px 20px 20px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#F8F5EF",
    borderRadius: "5px",
  },
  text: {
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: 500,
    marginLeft: "30px",
    display: "inline-block",
  },
});

interface Error {
  address: string;
  storeName: string;
  person: string;
}

const db = firebase.firestore();

interface IFormProps extends WithStyles<typeof styles> {
  store: any;
  companyId: string;
  id: string;
  users: any[];
}

const Form: React.FC<IFormProps> = (props) => {
  const { classes, store, companyId, id, users } = props;
  const history = useHistory();
  const { setCurrentTitle } = useContext(DashBoardContext);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const personOptions = users.map((user) => {
    return { name: user.name, value: user.id };
  });

  personOptions.unshift({ name: t("Select Contact Person"), value: "" });

  const addressOptions = prefectureList.map((item) => {
    return { value: item, name: t(item) };
  });
  addressOptions.unshift({ name: t("Select Address"), value: "" });

  const validationSchema = yup.object().shape({
    name: yup.string().required(t("Store Name is required")),
    address: yup.string().required(t("Prefecture is required")),
    townCity: yup.string().required(t("Town/City is required")),
    personId: yup.string().required(t("Person Name is required")),
  });

  const handleSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      const now = firebase.firestore.Timestamp.now();
      const params = { ...values, updatedAt: now };
      if (!store) params.createdAt = now;
      await db.collection(`Stores`).doc(id).set(params, { merge: true });
      successNotification(t("Data updated successfully"));
      history.push(`/companies/${companyId}`);
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
      personId: "",
      companyId,
      townCity: "",
      ...store,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data: unknown) => handleSubmit(data),
  });

  useEffect(() => {
    setCurrentTitle(store ? "updateStore" : "createStore");
  }, [store, setCurrentTitle]);

  return (
    <Spin spinning={isLoading}>
      <div className={classes.root}>
        <div>
          <img
            src={BackIcon}
            alt="back"
            style={{ height: "2em", marginBottom: "2em", cursor: "pointer" }}
            onClick={() => history.push(`/companies/${companyId}`)}
          />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className={classes.block} style={{ display: "flex" }}>
            <div style={{ marginRight: "20px" }}>
              <h2>{t("Store Information")}</h2>
            </div>
            <FormHelperText style={{ lineHeight: "2.66" }}>
              {t("All fields are required")}
            </FormHelperText>
          </div>

          <div className={classes.block}>
            <InputLabel>{t("Store Name")}</InputLabel>
            <TextField
              id="name"
              name="name"
              fullWidth
              value={formik.values.name}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </div>

          <div className={classes.block}>
            <InputLabel>{t("Store Name (English)")}</InputLabel>
            <TextField
              id="name"
              name="nameEn"
              fullWidth
              value={formik.values.nameEn}
              error={formik.touched.nameEn && Boolean(formik.errors.nameEn)}
              helperText={formik.touched.nameEn && formik.errors.nameEn}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </div>

          <div className={classes.block}>
            <InputLabel>{t("Prefecture")} </InputLabel>
            <Select
              name="prefecture"
              required
              options={addressOptions}
              value={formik.values.address}
              placeholder={t("Select Prefecture")}
              onChange={(value: string) =>
                formik.setFieldValue("address", value)
              }
              onBlur={formik.handleBlur}
              error={formik.touched.address && formik.errors.address}
            />
          </div>

          <div className={classes.block}>
            <InputLabel>{t("Town city (remaining address)")}</InputLabel>
            <TextField
              id="townCity"
              name="townCity"
              fullWidth
              value={formik.values.townCity}
              error={formik.touched.townCity && Boolean(formik.errors.townCity)}
              helperText={formik.touched.townCity && formik.errors.townCity}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </div>

          <div className={classes.block}>
            <InputLabel>{t("Contact Person")} </InputLabel>
            <Select
              name="personId"
              required
              options={personOptions}
              value={formik.values.personId}
              onChange={(value: string) =>
                formik.setFieldValue("personId", value)
              }
              onBlur={formik.handleBlur}
              error={formik.touched.personId && formik.errors.personId}
            />
          </div>

          <MaterialButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={isLoading}
          >
            {store ? t("Update") : t("Add")}
          </MaterialButton>
        </form>
      </div>
    </Spin>
  );
};

export default withStyles(styles)(Form) as any;
