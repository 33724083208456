/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { CheckboxGroup } from "components/Elements";
import { Job } from "components/interfaces";
import { FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  JobApplicationConfig,
  LANGUAGE_QUALIFICATIONS,
  RESIDENT_DATA,
  RESIDENT_STATUS,
} from "./helpers";

const { TITLE_VARIANT } = JobApplicationConfig as any;

type mode = "noWorkRestrictions" | "someRestrictions" | "other";
const MODE = ["noWorkRestrictions", "someRestrictions", "other"];
interface RSOptionProps extends Props {
  mode: mode;
}

const ResidentStatusOptions: React.FC<RSOptionProps> = ({
  mode,
  formik,
  haveError,
}: RSOptionProps) => {
  const [selectAll, setSelectAll] = useState(false);
  const { t } = useTranslation();

  const isAllChecked = (): boolean => {
    let isAllChecked: boolean = true;
    RESIDENT_DATA[mode].forEach((value: any) => {
      if (
        !Boolean(
          formik.values.residentData.find(
            (item: string) => item === value.value
          )
        )
      )
        isAllChecked = false;
    });
    return isAllChecked;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let values: string[] = extractUnchangedValue();
    let data = formik.values.residentData;
    if (e.target.checked) data.push(e.target.name);
    else data = data.filter((item) => item !== e.target.name);
    formik.setFieldValue("residentData", [...data, ...values]);
  };

  useEffect(() => {
    if (isAllChecked()) setSelectAll(true);
    else setSelectAll(false);
  }, [formik.values.residentData, mode]);

  const extractUnchangedValue = (): string[] =>
    formik.values.residentData.filter(
      (value: string) =>
        !RESIDENT_DATA[mode].some((item: any) => item.value === value)
    );

  return (
    <Card id="residentData">
      <FormControl>
        <FormGroup className="formGroup resident-checkbox">
          {RESIDENT_DATA[mode].map((item, index) => (
            <FormControlLabel
              key={index + item.value}
              value={item.value}
              control={
                <Checkbox
                  checked={
                    formik.values.residentData.filter(
                      (ite) => ite === item.value
                    ).length > 0
                  }
                  name={item.value}
                  onChange={handleChange}
                />
              }
              label={t(item.label)}
            />
          ))}
          <FormControlLabel
            control={
              <Checkbox
                checked={selectAll}
                onChange={(e) => {
                  let values = extractUnchangedValue();
                  formik.setFieldValue(
                    "residentData",
                    e.target.checked
                      ? [
                          ...values,
                          ...RESIDENT_DATA[mode].map((item) => item.value),
                        ]
                      : values
                  );
                  setSelectAll(e.target.checked);
                }}
              />
            }
            value={isAllChecked()}
            label={t("Select All")}
          />
          {haveError("residentData").message && (
            <FormHelperText error className="resident-error">
              {haveError("residentData").message}
            </FormHelperText>
          )}
        </FormGroup>
      </FormControl>
    </Card>
  );
};

interface Props {
  formik: FormikProps<Job>;
  haveError: any;
}
const ApplicationRequirements: React.FC<Props> = ({
  formik,
  haveError,
}: Props) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState<any>("noWorkRestrictions");

  useEffect(() => {
    if (
      MODE.includes(formik.values?.residentStatus || "") &&
      formik.values?.residentStatus !== mode
    )
      setMode(formik.values.residentStatus);
  }, [formik.values.residentStatus]);

  return (
    <div className="application-requirements-sections">
      <Typography variant={TITLE_VARIANT}>
        {t("Application Requirements")}
      </Typography>
      <FormControl
        component="fieldset"
        className="resident-status-checkbox-group"
        required
      >
        <FormLabel component="legend" id="residentStatus">
          {t("Resident Status")}
        </FormLabel>
        <RadioGroup
          name="residentStatus"
          value={formik.values.residentStatus}
          onChange={(e) => {
            formik.handleChange(e);
          }}
        >
          {RESIDENT_STATUS.map((item) => (
            <FormControlLabel
              value={item.value}
              key={item.value}
              control={
                <Radio
                  checked={item.value === formik.values.residentStatus && true}
                />
              }
              label={t(item.label)}
            />
          ))}
        </RadioGroup>
        {haveError("residentStatus").message && (
          <FormHelperText error>
            {haveError("residentStatus").message}
          </FormHelperText>
        )}
        {formik.values.residentStatus.length > 0 && (
          <ResidentStatusOptions
            formik={formik}
            mode={mode}
            haveError={haveError}
          />
        )}
      </FormControl>
      <FormControl
        component="fieldset"
        id="japaneseLevel"
        className="resident-status-checkbox-group"
      >
        <CheckboxGroup
          options={LANGUAGE_QUALIFICATIONS}
          value={formik.values.japaneseLevel.map((item: any) => {
            if ([5, 4, 11].includes(item)) return (11).toString();
            if ([1, 2, 3, 10].includes(item)) return (10).toString();
            return "12";
          })}
          label={`${t("Japanese Level")} *`}
          onChange={function (e: string[]): void {
            formik.setFieldValue(
              "japaneseLevel",
              e.map((item) => parseInt(item))
            );
          }}
        />
        {haveError("japaneseLevel").message && (
          <FormHelperText error>
            {haveError("japaneseLevel").message}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

export { ApplicationRequirements };
