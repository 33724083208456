import React, { createRef, useState, useEffect, useContext } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import createStyles from "@material-ui/core/styles/createStyles";
import { WithStyles } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import firebase from "../../firebase";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import BackIcon from "./../../assets/icons/backIcon.svg";
import { DashBoardContext } from "pages/DashboardLayout/DashboardContext";
import { catchExceptionCallback } from "utils";
import { Button, message, Spin } from "antd";

const styles = createStyles({
  root: {
    padding: 20,
  },
  block: {
    marginBottom: 20,
  },
  fileInputWrapper: {
    position: "relative",
    display: "inline-block",
    cursor: "pointer",
    height: "2.5rem",
  },
  fileInput: {
    minWidth: "14rem",
    margin: 0,
  },
  customFile: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    zIndex: 5,
    height: "2.5rem",
    padding: "0.5rem 1rem",
    lineHeight: 1.5,
    color: "#555",
    backgroundColor: "#fff",
    border: ".075rem solid #ddd",
    borderRadius: "0.25rem",
    boxShadow: "inset 0 .2rem .4rem rgba(0,0,0,.05)",
    "-webkit-user-select": "none",
    "-moz-user-select": "none",
    userSelect: "none",
    "&::before": {
      position: "absolute",
      top: "-.075rem",
      right: "-.075rem",
      bottom: "-.075rem",
      zIndex: 6,
      display: "block",
      content: '"Browse"',
      height: "2.5rem",
      padding: "0.5rem 1rem",
      lineHeight: 1.5,
      color: "#555",
      backgroundColor: "#eee",
      border: "0.075rem solid #ddd",
      borderRadius: "0 0.25rem 0.25rem 0",
    },
  },
  imagePreview: {
    marginTop: 20,
    height: 300,
    width: 300,
    "& img": {
      height: "100%",
      width: "100%",
      objectFit: "scale-down",
    },
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  companyData?: any;
}

const db = firebase.firestore();
const storage = firebase.storage();

const defaultValues = {
  category: "finding-job",
  url: "",
  title: "",
  titleEn: "",
  summary: "",
  summaryEn: "",
  contents: "",
  contentsEn: "",
} as any;

function Form(props: Props) {
  const { classes, companyData } = props;
  const history = useHistory();
  const { setCurrentTitle } = useContext(DashBoardContext);
  const [article, setArticle] = useState(companyData || defaultValues);
  const [loading, setLoading] = useState(false);

  const fileUpload = createRef<HTMLInputElement>();

  const handleImageChange = async (event: any) => {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      setArticle((job: any) => ({
        ...article,
        imageFile: file,
        imagePreviewUrl: reader.result,
      }));
    };

    reader.readAsDataURL(file);
  };

  function handleButtonClick() {
    fileUpload.current!.click();
  }

  useEffect(() => {
    setCurrentTitle("記事を追加");
  }, [setCurrentTitle]);

  const handleChange = (event: any) => {
    event.persist();
    setArticle((company: any) => ({
      ...company,
      [event.target.name]: event.target.value,
    }));
  };

  const changeCategory = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const target = event.target as HTMLSelectElement;
    setArticle((article: any) => ({
      ...article,
      category: target.value,
    }));
  };

  const save = async () => {
    const data = { ...article };

    if (!article.id) {
      data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
    }
    data.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
    try {
      setLoading(true);
      let imageUrl = "";
      if (data.imageFile) {
        const imageFileName = data.imageFile.name
          .split(".")
          .slice(0, -1)
          .join(".");
        const storageRef = storage.ref();
        const storageImagesRef = storageRef.child(
          `images/job/${
            imageFileName ? imageFileName + "-" + Date.now() : Date.now()
          }.jpg`
        );
        await storageImagesRef.put(data.imageFile);
        imageUrl = await storageImagesRef.getDownloadURL();
      }
      data.imagePreviewUrl = imageUrl ? imageUrl : data.imagePreviewUrl || "";
      delete data["imageFile"];
      var doc = db.collection("Articles").doc(article.url);
      await doc.set(data);
      message.success(article.id ? "変更しました。" : "登録しました。");
      history.push("/articles");
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setLoading(false);
    }
  };

  let imagePreview = null;
  if (article.imagePreviewUrl) {
    imagePreview = (
      <div className={classes.imagePreview}>
        <img src={article.imagePreviewUrl} alt="al" />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Spin spinning={loading}>
        <div>
          <img
            src={BackIcon}
            alt="back"
            onClick={() => history.push("/articles")}
            style={{ height: "2em", cursor: "pointer" }}
          />
        </div>
        <div>
          <FormControl
            variant="outlined"
            style={{ minWidth: "100%" }}
            component={"div"}
            margin="normal"
          >
            <InputLabel htmlFor="category">カテゴリー *</InputLabel>
            <Select
              name={"category"}
              value={article.category}
              onChange={changeCategory}
              input={
                <OutlinedInput
                  name="カテゴリー"
                  id="category"
                  labelWidth={110}
                />
              }
            >
              <MenuItem value="finding-job">Finding a Job</MenuItem>
              <MenuItem value="resumes-cover-letters">
                Resumes & Cover Letters
              </MenuItem>
              <MenuItem value="interviewing">Interviewing</MenuItem>
              <MenuItem value="starting-job">Starting a New Job</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className={classes.block}>
          <InputLabel>記事のURL</InputLabel>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="url"
                name="url"
                placeholder="例：become-a-networking-expert-in-7-steps"
                helperText="「xxx」に該当するユニークな値を部分を記入してください「https://quickjobsjapan.com/category/xxx」"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={article.url}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.block}>
          <InputLabel>タイトル</InputLabel>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="title"
                name="title"
                placeholder="日本語"
                helperText=""
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={article.title}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="titleEn"
                name="titleEn"
                placeholder="英語"
                helperText=""
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={article.titleEn}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </div>

        <div className={classes.block}>
          <InputLabel>概要</InputLabel>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="summary"
                name="summary"
                placeholder="日本語"
                helperText=""
                fullWidth
                multiline
                rows={10}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={article.summary}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="summaryEn"
                name="summaryEn"
                placeholder="英語"
                helperText=""
                fullWidth
                multiline
                rows={10}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={article.summaryEn}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </div>

        <div className={classes.block}>
          <InputLabel>内容</InputLabel>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="contents"
                name="contents"
                placeholder="日本語"
                helperText=""
                fullWidth
                multiline
                rows={40}
                rowsMax="100"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={article.contents}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="contentsEn"
                name="contentsEn"
                placeholder="英語"
                helperText=""
                fullWidth
                multiline
                rows={40}
                rowsMax="100"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={article.contentsEn}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </div>

        <div className={classes.block}>
          <InputLabel>画像</InputLabel>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={6}>
              <div className={classes.fileInputWrapper}>
                <input
                  ref={fileUpload}
                  className={classes.fileInput}
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
                <span
                  className={classes.customFile}
                  onClick={handleButtonClick}
                >
                  {article.imageFile && article.imageFile.name
                    ? article.imageFile.name.length >= 20
                      ? `${article.imageFile.name.slice(0, 20)}...`
                      : article.imageFile.name
                    : "画像を選択"}
                </span>
              </div>
              {imagePreview}
            </Grid>
          </Grid>
        </div>

        <Button
          loading={loading}
          type="primary"
          htmlType="submit"
          onClick={save}
        >
          設定する
        </Button>
      </Spin>
    </div>
  );
}

const FormWithRouter = withRouter(Form) as any;
export default withStyles(styles)(FormWithRouter) as any;
