import React from "react";
import { RouteComponentProps, withRouter } from "react-router";

interface IProps extends RouteComponentProps<any> {}

interface IState {}

class _ScrollToTop extends React.Component<IProps, IState> {
  componentDidUpdate(prevProps: any) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}

export const ScrollToTop = withRouter(_ScrollToTop as any);
