import { axiosConfig } from "auth";
import Axios from "axios";
import firebaseConfig, { api } from "config/firebase";

const COMPANY_URL = `${api.host}/company`;
const USER_URL = `${api.host}/user`;

export const createCompany = async (payload: unknown) =>
  Axios.post(`${COMPANY_URL}/create`, payload, await axiosConfig());

export const inviteCompanyUser = async (payload: unknown) =>
  Axios.post(`${COMPANY_URL}/invite`, payload, await axiosConfig());

export const getUser = async (uid: string) => {
  const { data } = await Axios.get(`${USER_URL}/${uid}`, await axiosConfig());
  return data;
};

export const getCompanyLoginToken = async (uid: string) => {
  const { data } = await Axios.get(
    `${COMPANY_URL}/loginToken/${uid}`,
    await axiosConfig()
  );
  return data;
};
export const updateUserDetail = async (uid: string, payload: unknown) =>
  Axios.post(`${USER_URL}/update-detail/${uid}`, payload, await axiosConfig());

export const identitytoolkitSignInWithPassword = async (payload: unknown) =>
  await Axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${firebaseConfig.apiKey}`,
    payload
  );

export const deleteUser = async (uid: string) =>
  Axios.delete(`${USER_URL}/delete/${uid}`, await axiosConfig());
