import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import createStyles from "@material-ui/core/styles/createStyles";
import { WithStyles } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import isEmpty from "validator/lib/isEmpty";
import isEmail from "validator/lib/isEmail";
import isLength from "validator/lib/isLength";
import { api } from "../../config/firebase";
import { axiosConfig } from "../../auth";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DashBoardContext } from "pages/DashboardLayout/DashboardContext";
import BackIcon from "../../assets/icons/backIcon.svg";
import { message, Spin } from "antd";
import { catchExceptionCallback } from "utils";

const styles = createStyles({
  root: {
    padding: 20,
  },
  block: {
    marginBottom: 20,
  },
});

interface Admin {
  id: string;
  name: string;
  email: string;
  password: string;
  phone: string;
}

interface Error {
  name: string;
  email: string;
  password: string;
  phone: string;
}

interface Props extends WithStyles<typeof styles> {
  adminData?: Admin;
}

const defaultValues: Admin = {
  id: "",
  name: "",
  email: "",
  password: "",
  phone: "",
};

function Form(props: Props) {
  const { classes, adminData = defaultValues } = props;
  const history = useHistory();
  const { setCurrentTitle } = useContext(DashBoardContext);
  const [admin, setAdmin] = useState(adminData);
  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState({} as Error);

  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setAdmin((admin) => ({
      ...admin,
      [event.target.name]: event.target.value,
    }));
  };

  const save = async () => {
    if (!validate(admin)) {
      return;
    }
    try {
      setIsLoading(true);
      const url = `${api.host}/admin/account/${admin.id ? "update" : "create"}`;
      await axios.post(url, admin, await axiosConfig());
      message.success(
        t("Admin successfully " + (admin.id ? "updated" : "created"))
      );
      history.push("/admins");
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setIsLoading(false);
    }
  };

  const validate = (data: Admin) => {
    const errors = {} as Error;

    if (isEmpty(data.name)) {
      errors.name = "入力してください。";
    }

    if (isEmpty(data.email)) {
      errors.email = "入力してください。";
    } else if (!isEmail(data.email)) {
      errors.email = "正しく入力してください。";
    }

    if (!admin.id) {
      if (isEmpty(data.password)) {
        errors.password = "入力してください。";
      } else if (isLength(data.password, { max: 7 })) {
        errors.password = "8文字以上入力してください。";
      }
    } else {
      if (!isEmpty(data.password) && isLength(data.password, { max: 7 })) {
        errors.password = "8文字以上入力してください。";
      }
    }

    if (isEmpty(data.phone)) {
      errors.phone = "入力してください。";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    setCurrentTitle("addAdmin");
  }, [setCurrentTitle]);

  return (
    <div className={classes.root}>
      <div>
        <img
          src={BackIcon}
          alt="back"
          onClick={() => history.push("/admins")}
          style={{ height: "2em", marginBottom: "2em", cursor: "pointer" }}
        />
      </div>
      <Spin spinning={isLoading}>
        <div className={classes.block}>
          <InputLabel>担当者名</InputLabel>
          <TextField
            id="name"
            name="name"
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={admin.name}
            onChange={handleChange}
          />
          {errors.name && <FormHelperText error>{errors.name}</FormHelperText>}
        </div>

        <div className={classes.block}>
          <InputLabel>メールアドレス</InputLabel>
          <TextField
            id="email"
            name="email"
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={admin.email}
            onChange={handleChange}
          />
          {errors.email && (
            <FormHelperText error>{errors.email}</FormHelperText>
          )}
        </div>

        <div className={classes.block}>
          <InputLabel>{t("Phone Number")}</InputLabel>
          <TextField
            id="phone"
            name="phone"
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={admin.phone}
            onChange={handleChange}
          />
          {errors.phone && (
            <FormHelperText error>{errors.phone}</FormHelperText>
          )}
        </div>

        <div className={classes.block}>
          <InputLabel>パスワード</InputLabel>
          <TextField
            id="password"
            name="password"
            type="password"
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={admin.password}
            onChange={handleChange}
          />
          {errors.password && (
            <FormHelperText error>{errors.password}</FormHelperText>
          )}
        </div>

        <Button
          disabled={isLoading}
          variant="contained"
          color="primary"
          size="large"
          onClick={save}
        >
          設定する
        </Button>
      </Spin>
    </div>
  );
}

export default withStyles(styles)(Form) as React.FC<any>;
