import { createStyles } from "@material-ui/styles";
import { Job } from "components/interfaces";
import * as yup from "yup";
import { t } from "i18next";

export const EMPLOYMENT_TYPE = [
  {
    label: "Part Time",
    value: "partTime",
  },
  {
    label: "Full Time",
    value: "fullTime",
  },
  {
    label: "Contract",
    value: "contract",
  },
];

export const AGE_QUALIFICATION_OPTIONS = [
  { label: "High school graduate or older", value: "highSchoolGraduatePlus" },
  { label: "18 years old or older", value: "18yearPlus" },
];

export const RESIDENT_STATUS = [
  {
    label: "No Work Restriction",
    value: "noWorkRestrictions",
  },
  {
    label: "Some Restriction",
    value: "someRestrictions",
  },
  {
    label: "Others",
    value: "other",
  },
];

export const RESIDENT_DATA = {
  noWorkRestrictions: [
    {
      label: "Permanent Residents",
      value: "permanentResidents",
    },
    {
      label: "Spouse of Japanese",
      value: "spouseOfJapanese",
    },
    {
      label: "Spouse of Permanent Resident",
      value: "spouseOfPermanentResident",
    },
    {
      label: "Long-term Resident",
      value: "visaLongTermResident",
    },
  ],
  someRestrictions: [
    { label: "Studying abroad", value: "studyAbroad" },
    {
      label: "Designated Activities",
      value: "designatedActivities",
    },
    {
      label: "Family Residency",
      value: "familyResidency",
    },
    {
      label: "Designated Skills",
      value: "designatedSkills",
    },
    {
      label: "Technical Internship",
      value: "techincalInternship",
    },
  ],
  other: [
    { label: "Diplomacy", value: "diplomacy" },
    { label: "Official", value: "official" },
    { label: "Teaching", value: "teaching" },
    { label: "Press", value: "press" },
    { label: "Religion", value: "religion" },
    {
      label: "Management and Administration",
      value: "managementAndAdminstration",
    },
    {
      label: "Legal and Accounting services",
      value: "legalAndAccountingServices",
    },
    {
      label: "Highly Specialized profession",
      value: "highlySpecilizedProffesion",
    },
    {
      label: "Instructor",
      value: "visaInstructor",
    },
    { label: "Research", value: "research" },
    { label: "Education", value: "education" },
    { label: "Technical", value: "technical" },
    {
      label: "Humanities and International Services",
      value: "humanitiesAndInternationalServices",
    },
    { label: "Entertainment", value: "entertainment" },
    { label: "Technical Skills", value: "technicalSkills" },
    { label: "Medical care", value: "medicalCare" },
    { label: "Arts", value: "arts" },
    { label: "Nursing Care", value: "nursingCare" },
    {
      label: "Cultural Activities",
      value: "culturalActivities",
    },
    {
      label: "Short-term activities",
      value: "shortTermActivities",
    },
    {
      label: "Trainee",
      value: "visaTrainee",
    },
    {
      label: "Intra-Company Transferee",
      value: "visaIntraCompanyTransferee",
    },
    {
      label: "Temporary Visitor",
      value: "visaTemporaryVisitor",
    },
  ],
};

export const BUSINESS_TYPE = [
  { label: "Cleaning/Bed Making", value: "cleanOrBedMaking" },
  { label: "Convenience Store", value: "convenienceStore" },
  { label: "Food & Beverage", value: "restaurants" },
  { label: "Light Work/Factory", value: "lightWorkOrFactory" },
];

export const TAG_SELECTION = {
  welcome: [
    {
      label: "Long-term employees are Welcome",
      value: "Long-term employees are Welcome",
    },
    {
      label: "Housewife/ House wife are Welcome",
      value: "Housewife/ House wife are Welcome",
    },
    {
      label: "International students are Welcome",
      value: "International students are Welcome",
    },
  ],
  workingHours: [
    { label: "Shift System", value: "Shift System" },
    {
      label: "Weekdays only Ok",
      value: "Weekdays only Ok",
    },
    { label: "No Night shift", value: "No Night Shift" },
    {
      label: "Regular work hours",
      value: "Regular work hours",
    },
    {
      label: "Regular closing hours",
      value: "Regular closing hours",
    },
  ],
  others: [
    { label: "Speedy job offer", value: "Speedy job offer" },
    {
      label: "No resume required",
      value: "No resume required",
    },
    {
      label: "Hourly wage increase",
      value: "Hourly wage increase",
    },
    {
      label: "Foreign staffs are available",
      value: "Foreign affairs are available",
    },
    {
      label: "Meals and Meals are included",
      value: "Meals and meals are included",
    },
    { label: "Dormitory", value: "Dormitory" },
    {
      label: "Online interview OK",
      value: "Online Interview OK",
    },
    {
      label: "Piercing/Tattoo OK",
      value: "Piercing/Tattoo OK",
    },
  ],
  experience: [
    { label: "No experience required", value: "No experience required" },
    { label: "Experience is preferred", value: "Experience is preferred" },
  ],
};

export const LANGUAGE_QUALIFICATIONS = [
  { description: "Can speak", label: "Can speak", value: "10" },
  {
    description: "Can speak a little",
    label: "Can speak a little",
    value: "11",
  },
  {
    description: "Can't speak",
    label: "Cannot speak",
    value: "12",
  },
];

export const LANGUAGE_QUALIFICATIONS_DETAILS = [
  "N1 (Native Level)",
  "N3 (Daily Conversation level with Japanese)",
  "N5 (Beginner Level)",
  "N2 (Business Level)",
  "N4 (Daily Conversation level with Japanese slowly)",
  "Unable to speak Japnese",
];

export const FORM_LEVEL_ERROR_MAP: any = {
  titleEn: "Title (English)",
  jobDescriptionEn: "Job Description (English)",
  title: "Title (Japanese)",
  jobDescription: "Job Description (Japanese)",
};
export const INITIAL_VALUES: Job = {
  storeId: "",
  storeName: "",
  storeNameEn: "",
  contactPersonsIds: [],
  employmentType: "",
  businessType: "",
  residentStatus: "noWorkRestrictions",
  residentData: [],
  companyName: "",
  companyNameEn: "",
  companyId: "",
  japaneseLevel: [],
  welcomeTags: [],
  workingHoursTags: [],
  othersTags: [],
  experienceTags: [],
  imageFile: "",
  imagePreviewUrl: "",
  travelExpenses: "no",
  travelExpensesNote: "",
  workingHours: [
    {
      jobType: "",
      jobTypeEn: "",
      salary: "",
      minHourlyWage: "",
      maxHourlyWage: "",
      minHourlyWageEn: "",
      maxHourlyWageEn: "",
      salaryEn: "",
      from: "12:00",
      to: "12:00",
      comment: "",
      commentEn: "",
    },
  ],

  title: "",
  titleEn: "",
  addressJa: "",
  addressEn: "",
  jobDescription: "",
  jobDescriptionEn: "",
};

export const styles = createStyles({
  fromWrapper: {
    marginTop: 32,
    "&  h6": {
      margin: "1rem 0",
    },
    "& .person-in-charge-section, & .industry-registration-section, & .application-requirements-sections, & .job-ad-listing-sections":
      {
        width: "100%",
        "& > *": {
          marginBottom: "1rem",
          width: "100%",
        },
      },
    "& .formGroup": {
      flexDirection: "row",
      "& > *": {
        marginRight: "3rem",
      },
    },
    "& .resident-checkbox": {
      padding: "1rem",
      marginTop: "0.5rem",
    },
    "& .resident-status-checkbox-group .MuiFormGroup-root": {
      flexDirection: "row",
    },
    "& .job-ad-listing-sections": {
      "& .header": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      },
      "& .MuiFormControl-root": {
        marginBottom: "1rem",
        width: "100%",
      },
    },
    "& .align-center": {
      margin: "0.5rem 0",
      width: "100%",
      textAlign: "center",
    },
    "& .card-body": {
      padding: "1rem 1rem",
    },
    "& .card-header": {
      paddingBottom: "1rem",
    },
    "& .separator": {
      marginTop: "1rem",
    },
    "& .hourly-wage": {
      display: "flex",
    },
    "& .hourly-wage > *:first-child": {
      marginRight: "0.5rem",
    },
    "& .danger": {
      backgroundColor: "red",
      color: "white",
    },
    "& .footer": {
      margin: "2rem 0",
      padding: "1rem",
      display: "flex",
      justifyContent: "space-between",
    },
    "& .resident-error": {
      width: "100%",
    },
    "& .image-label": {
      paddingBottom: "0.75rem",
    },
    "& .chip": {
      backgroundColor: "#5a647a",
      color: "white",
      marginLeft: "0.25rem",
    },
  },
});

export const JobApplicationConfig = {
  INPUT_VARIANT: "outlined",
  TITLE_VARIANT: "h6",
};

export const MAX_TEXT_LENGTH = 50;
export const MAX_JOB_DESCRIPTION_LENGTH = 20000;
export const MAX_JOB_TITLE_LENGTH = 200;
const withSalaryValidationSchema = yup.object().shape({
  jobType: yup.string().required(t("Job type is required")),
  jobTypeEn: yup.string().required(t("Job type is required")),
  salary: yup.string().required(t("Salary is required")),
  salaryEn: yup
    .string()
    .required(t("Salary is required"))
    .matches(/^(\d+(\.\d+)?)$/, {
      message: t("Enter valid salary"),
    }),
  from: yup.string().required(t("Shift start time is required")),
  to: yup.string().required(t("Shift end time is required")),
  comment: yup.string(),
  commentEn: yup.string(),
});

const withMinMaxValidationSchema = yup.object().shape({
  jobType: yup.string().required(t("Job type is required")),
  jobTypeEn: yup.string().required(t("Job type is required")),
  minHourlyWage: yup.string().required(t("Min Hour wage is required")),
  maxHourlyWage: yup.string().required(t("Max Hour wage is required")),
  minHourlyWageEn: yup
    .string()
    .required(t("Min Hour wage is required"))
    .matches(/^(\d+(\.\d+)?)$/, {
      message: t("Enter valid minimum wage"),
    }),
  maxHourlyWageEn: yup
    .string()
    .required(t("Max Hour wage is required"))
    .matches(/^(\d+(\.\d+)?)$/, {
      message: t("Enter valid maximum wage"),
    })
    .test({
      name: "minHourlyWageEn",
      exclusive: false,
      params: {},
      message: t("Max hourly wage must be greater than min hourly wage"),
      test: (value, context) => {
        return (
          !context.parent.minHourlyWageEn ||
          context.parent.minHourlyWageEn.length < 1 ||
          Number(value) >= Number(context.parent.minHourlyWageEn)
        );
      },
    }),
  from: yup.string().required(t("Shift start time is required")),
  to: yup.string().required(t("Shift end time is required")),
  comment: yup.string(),
  commentEn: yup.string(),
});

export const ValidationSchema = yup.object().shape({
  companyId: yup.string().required(t("Company Name is required")),
  titleEn: yup.string().required(t("Job title is required")),
  jobDescription: yup.string().required(t("Job details is required")),
  jobDescriptionEn: yup.string().required(t("Job details is required")),
  storeId: yup.string().required(t("Store ID is required")),
  contactPersonsIds: yup.array().min(1, t("Contact person is required")),
  employmentType: yup.string().required(t("Employment type is required")),
  businessType: yup.string().required(t("Business type is required")),
  residentStatus: yup.string().required(t("Resident status is required")),
  residentData: yup.array().min(1, t("Please select resident checkboxes")),
  title: yup.string().required(t("Job title is required")),
  addressEn: yup.string().required(t("Address is required")),
  addressJa: yup.string().required(t("Address is required")),
  japaneseLevel: yup.array().min(1, t("Japanese language level is required")),
  travelExpenses: yup.string().required(t("Travel expenses is required")),
  workingHours: yup
    .array()
    .when("employmentType", {
      is: (val: string) => val === "fullTime",
      then: (schema) => schema.of(withSalaryValidationSchema),
      otherwise: (schema) => schema.of(withMinMaxValidationSchema),
    })
    .min(1, t("Working hours table is required")),
});

export const getFieldErrorNames = (formikErrors: any) => {
  const transformObjectToDotNotation = (
    obj: any,
    prefix = "",
    result: any = []
  ) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;

      const nextKey: any = prefix ? `${prefix}.${key}` : key;
      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};
