import { notification } from "antd";

const showError = (message: string) => {
  notification.error({
    duration: 5,
    message: message || "Error has occurred",
  });
};

const showSuccess = (message: string) => {
  notification.success({
    duration: 5,
    message: message || "Error has occurred",
  });
};
export { showError, showSuccess };
