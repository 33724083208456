import React, { useContext } from "react";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import createStyles from "@material-ui/core/styles/createStyles";
import { withStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import { DashBoardContext } from "pages/DashboardLayout/DashboardContext";

const styles = createStyles({
  addButton: {
    padding: "5px 25px 5px 25px",
    backgroundColor: "#39404F",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    fontSize: "10px",
    color: "#FFFFFF",
    fontWeight: 500,
  },
});

function _Button(props: any) {
  const history = useHistory();
  const { path, text, classes, button_type } = props;
  const { setCurrentTitle } = useContext(DashBoardContext);

  const addJob = () => {
    setCurrentTitle("jobAdd");
    history.push(path);
  };

  if (button_type === "button") {
    return (
      <>
        <button onClick={() => addJob()} className={classes.addButton}>
          {text}
        </button>
      </>
    );
  }
  if (button_type === "link") {
    return (
      <>
        <Link component={RouterLink} to={path} className={classes.addButton}>
          {text}
        </Link>
      </>
    );
  }

  return null;
}

export const Button = withStyles(styles)(_Button);
