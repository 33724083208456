import React, { MouseEvent, useContext, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Brand from "../../assets/images/brand.png";
import Job from "../../assets/images/jobs.svg";
import Company from "../../assets/images/company.svg";
import Article from "../../assets/images/article.svg";
import User from "../../assets/images/user.svg";
import Admin from "../../assets/images/admin.png";
import Logout from "../../assets/images/logout.svg";
import { AuthContext } from "AuthProvider";
import { makeStyles } from "@material-ui/core";

const styles = makeStyles({
  profileImage: {
    width: "75%",
    textAlign: "center",
    padding: "10px",
  },
  navLink: {
    color: "white",
    textDecoration: "none",
  },
  linkImage: {
    padding: "1px 12px 0px 0px",
  },
});
const SideNav = () => {
  const classes = styles();
  const {
    loading,
    authenticated,
    logout: _logout,
    role,
  } = useContext(AuthContext);

  const [active, setActive] = useState("");

  const logout = async (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    _logout();
  };

  if (loading || !authenticated) {
    return null;
  }

  interface ILinkItem {
    active: boolean;
  }

  const Container = styled.div`
    margin: 0px;
    background-color: #39404f;
    height: 100vh;
    width: auto;
    position: relative;
  `;
  const LinkItem = styled.li<ILinkItem>`
    display: flex;
    box-sizing: border-box;
    border: 1px solid #696969;
    width: 100%;
    list-style-type: none;
    padding: 20px 40px 20px 40px;
    color: white;
    ${(props) => (props.active ? "background-color: #696969" : "")}
  `;

  const ProfileSection = styled.div`
    background-color: #e26e30;
    padding: 40px 30px 40px;
    text-align: center;
  `;

  const MainContainer = styled.div`
    position: fixed;
    width: 15%;
  `;

  const LinkList = styled.ul`
    display: block;
    padding: 0px;
    margin: 0px;
  `;

  return (
    <>
      <MainContainer>
        <ProfileSection>
          <img src={Brand} alt="brand" className={classes.profileImage} />
        </ProfileSection>
        <Container>
          <LinkList>
            <LinkItem active={active === "求人" ? true : false}>
              <img src={Job} alt="job" className="linkImage" />
              <Link
                to={"/jobs"}
                onClick={() => setActive("求人")}
                className={classes.navLink}
              >
                求人
              </Link>
            </LinkItem>
            <LinkItem active={active === "企業" ? true : false}>
              <img src={Company} alt="company" className={classes.linkImage} />
              <Link
                to={"/companies"}
                onClick={() => setActive("企業")}
                className={classes.navLink}
              >
                企業
              </Link>
            </LinkItem>
            <LinkItem active={active === "記事一覧" ? true : false}>
              <img src={Article} alt="article" className={classes.linkImage} />
              <Link
                to={"/articles"}
                onClick={() => setActive("記事一覧")}
                className={classes.navLink}
              >
                記事一覧
              </Link>
            </LinkItem>
            {role === "owner" && (
              <LinkItem active={active === "ユーザー" ? true : false}>
                <img src={User} alt="user" className={classes.linkImage} />
                <Link
                  to={"/users"}
                  onClick={() => setActive("ユーザー")}
                  className={classes.navLink}
                >
                  ユーザー
                </Link>
              </LinkItem>
            )}
            {role === "owner" && (
              <LinkItem active={active === "管理者" ? true : false}>
                <img
                  src={Admin}
                  style={{ height: "24px" }}
                  className={classes.linkImage}
                  alt="admin"
                />
                <Link
                  to={"/admins"}
                  onClick={() => setActive("管理者")}
                  className={classes.navLink}
                >
                  管理者
                </Link>
              </LinkItem>
            )}
          </LinkList>
          <LinkItem active={active === "応募者" ? true : false}>
            <img src={Job} className={classes.linkImage} alt="Job" />
            <Link
              to={"/applicants"}
              onClick={() => setActive("応募者")}
              className={classes.navLink}
            >
              応募者
            </Link>
          </LinkItem>
          <LinkItem active={active === "ログアウト" ? true : false}>
            <img src={Logout} className={classes.linkImage} alt="logout" />
            <Link
              to={"/"}
              onClick={(e) => logout(e)}
              className={classes.navLink}
            >
              ログアウト
            </Link>
          </LinkItem>
        </Container>
      </MainContainer>
    </>
  );
};

export { SideNav };
